import React, { useState, useContext } from "react";
import Star from "../assets/star.svg";
import Users from "../assets/users.svg";
import Refresh from "../assets/refresh.svg";
import Ray from "../assets/ray.svg";
import Angie from "../assets/angie.png";
import Andres from "../assets/andres.png";
import Carlos from "../assets/carlos.png";
// import Danay from "../assets/danay.png";
import Edison from "../assets/edinson.png";
// import Emmanuel from "../assets/emmanuel.png";
import Jairo from "../assets/jairo.png";
import Jean from "../assets/jean.png";
import Jorge from "../assets/jorge.png";
import JoseG from "../assets/joseg.png";
import JoseV from "../assets/josev.png";
import Kate from "../assets/katerin.png";
// import Kelvin from "../assets/kelvin.png";
import Kevin from "../assets/kevin2.png";
import Laura from "../assets/laura.png";
import Laurina from "../assets/laurina.png";
import Leidy from "../assets/leidy.png";
import Marianella from "../assets/marianella.png";
import Morelia from "../assets/morelia.png";
// import Natalia from "../assets/natalia.png";
import Stiven from "../assets/stven.png";
import Valentina from "../assets/valentina.png";
import A1 from "../assets/6.png";
import Carousel from "react-elastic-carousel";
import ContactForm from "./ContactForm";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import start from "../assets/startIcon.svg";
import avatar from "../assets/avatar.svg";
import light from "../assets/light.svg";
// import luisEscaf from "../assets/luisEscaf.png";
import { store } from "./Context/store";

const AboutUs = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  // let userFilter;

  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }
  let projectDirector = traducir("Project director", "Director de proyectos");
  let LeadDeveloper = traducir("Lead developer", "Desarrollador líder");
  const users = [
    { name: "Carlos", label: "junta", jun: "junta", position: projectDirector, img: Carlos },
    { name: "Edinson", label: "developer", position: "Back-end", img: Edison },
    { name: "Jorge", label: "junta", jun: "junta", position: traducir("General manager", "Gerente general"), img: Jorge },
    { name: "Katerin", label: "developer", position: "Front-end", img: Kate },
    { name: "Kevin", label: "developer", jun: "junta", position: LeadDeveloper, img: Kevin },
    { name: "Laura", label: "developer", position: "QA", img: Laura },
    { name: "Leidy", label: "developer", jun: "junta", position: LeadDeveloper, img: Leidy },
    { name: "Stiven", label: "developer", position: "Full stack", img: Stiven },
    { name: "Valentina", label: "developer", position: "Full stack", img: Valentina },
    { name: "Luis Escaf", label: "junta", jun: "junta", position: "CEO", img: avatar },
    { name: "Luis Carlos Escaf", label: "junta", jun: "junta", position: "Research", img: avatar },
  ];

  const [optionSelected, setOptionSelected] = useState([
    {
      selected: false,
      option: "",
    },
  ]);

  const userFilter = !optionSelected.selected
    ? users
    : users.filter((user) => user.label === optionSelected.option || user.jun === optionSelected.option);

  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 550, itemsToShow: 3, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 5, pagination: false },
    { width: 1150, itemsToShow: 5, itemsToScroll: 2, },
    { width: 1450, itemsToShow: 5,  },
    { width: 1750, itemsToShow: 6, },
  ];
  const renderListUser = () => {
    let tempList = [];
    if (Array.isArray(userFilter)) {
      // eslint-disable-next-line array-callback-return
      userFilter.map((item, index) => {
        tempList.push(
          <div className="cardUser my-3" key={index}>
            <div>
              <img src={item.img} alt={`member-${index}`} width="200" height="150" className="rounded-t-lg" />
            </div>
            <div className="text-center mt-2">
              <span className="prussianBlue fw-bold">{item.name}</span>
            </div>
            <div className="text-center mb-3">
              <span className="prussianBlue" style={{ fontWeight: "500" }}>
                {item.position}
              </span>
            </div>
          </div>
        );
      });
    }
    return tempList;
  };
  const [expanded, setExpanded] = useState(false);

  const AccordionComponent = () => {
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      <div className=" container flex flex-col items-center justify-center">
        <Accordion
          sx={{ borderRadius: "14px !important", width: "100% " }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="p-5 bgAccordionvis mt-2 "
        >
          <AccordionSummary
            sx={{ borderRadius: "14px", width: "100% "  }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <span class="fw-bold fs-48 colorSecundary">{traducir("Mission", "Misión")}</span>
          </AccordionSummary>
          <AccordionDetails sx={{ borderRadius: "14px", width: "100% " }}>
            {traslate(
              <p class="colorSecundary fs-26-12 fw-bold">Add value to our clients through the transformation of digital processes.</p>,
              <p class="colorSecundary fs-26-12 fw-bold">
                Incrementar el valor de nuestros clientes a través de la transformación de procesos digitales, aumentando la automatización
                y optimización, adaptando la tecnología a los diferentes entornos.
              </p>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ borderRadius: "14px !important", width: "100% ", position: "initial" }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="p-5 bgAccordionvis  mt-5"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header"
          sx={{ borderRadius: "14px", width: "100% ", position: "initial",  }}

          >
            <span className="fw-bold fs-48 colorSecundary">{traducir("Vision", "Visión")}</span>
          </AccordionSummary>
          <AccordionDetails
          className=" transition ease-in-out delay-150"
          >
            {traslate(
              <p class="colorSecundary fs-26-12 fw-bold">
                Contribute to the development of excellent products focused on the innovations of processes for the health sector.{" "}
              </p>,
              <p class="colorSecundary fs-26-12 fw-bold">
                Contribuir en el mercado de manera significativa en la creación de excelentes productos enfocados en la innovación de
                procesos en empresas de salud. Ser posicionados como empresa líder en innovación y diseño, llevando nuestros productos a
                niveles de máximo rendimiento y mejorando la experiencia de los usuarios cada día.
              </p>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  return (
    <>
      <section class="bannerAbout py-4">
        <div class="mx-5 px-4 h-100 px-md-5">
          <div class="row gx-5 h-100 md:pb-28 items-end justify-start  ">
            <div class="col-12">
              <div class="text-start my-5 ">
                <h1 class="md_text-center text-start display-5 fw-bold text-white mb-2 fs-96-80 mt-5">{traducir("We are TTN", "Somos TTN Company")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="md:py-5  sectionSecundary bg-white">
        <div class="container w-100  md:flex pt-5 items-center justify-center">
          <div class="col-md-6 col-12 md:px-5 py-3 ">
            {traslate(
              <div class="md:px-5 fs-26-12 md:mt-5">
                <p class="text-justify colorGray  fwBold">
                  TTN was founded in 2020 with the support of<span class="colorYellow"> COFCA</span> with the vision of developing
                  technological products that solve the health sector’s needs.
                </p>
                <h1 class="pt-4 pb-3 prussianBlue fw-bold">What is Newbusiness?</h1>
                <p class="text-justify colorGray  fwBold">
                  Is the<span class="colorYellow"> development of ideas</span> with the goal of presenting new products to the market.
                </p>
              </div>,
              <div class="md:px-5 fs-26-12 md:mt-5">
                <p class="text-justify colorGray  fw-bold">
                  Fundados en 2020 con el apoyo de la <span class="colorYellow">Clínica Oftalmológica del Caribe</span>, somos una startup
                  nacida de una idea: desarrollar productos tecnológicos para solucionar las necesidades del sector salud.
                </p>
                <h1 class="pt-4 pb-3 prussianBlue fw-bold">¿Que es Newbusiness?</h1>
                <p class="text-justify colorGray  fw-bold">
                  Es el <span class="colorYellow">desarrollo de nuevas ideas</span> con el objetivo presentar un nuevo modelo de producto en
                  el mercado.
                </p>
              </div>
            )}
          </div>
          <div className="col-md-6 col-12 p-0 text-end flex items-end justify-end">
            <img className=" imgBRL300 hiddenMobile " src={A1} width="" height="auto" alt="" />
          </div>
          <div className=" sm:hidden text-[24px] text-[#003264] font-bold flex items-center justify-center text-center space-x-2 border-2 p-2 mt-4 rounded-xl border-[#01A0F6]">
            <div className="flex"></div>
            <span className="flex"> <img src={light} alt="ligth" className="h-max items-center self-center ml-4"/>
             {traducir("If you have an idea, we will make it a reality","Si tienes una idea, la haremos realidad")}</span>
                
          </div>
        </div>
      </section>
      <section class="md:py-5">
        <div class="md:p-5 my-5">
          {/* <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item pb-5">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button bgAccordionMis collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  <span class="fw-bold fs-48 colorSecundary">Misión</span>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body bodyMis">
                  <span class="fw-bold fs-48 colorSecundary">Misión</span>
                  <p class="colorSecundary fs-20">
                    Incrementar el valor de nuestros clientes a través de la transformación de procesos digitales, aumentando la
                    automatización y optimización, adaptando la tecnología a los diferentes entornos.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item pb-5">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button bgAccordionvis collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  <span class="fw-bold fs-48 colorSecundary">Visión</span>
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body bodyVis">
                  <span class="fw-bold fs-48 colorSecundary">Visión</span>
                  <p class="colorSecundary fs-20">
                    Incrementar el valor de nuestros clientes a través de la transformación de procesos digitales, aumentando la
                    automatización y optimización, adaptando la tecnología a los diferentes entornos.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <AccordionComponent />
        </div>
      </section>
      <section class="md:bg-white">
        <div class="pb-5" style={{ backgroundColor: "#E1F5FF", borderRadius: " 0% 0% 50% 0%" }}>
          <div class="container flex flex-col items-center justify-center">
            <div class="text-center py-5">
              <h1 class="fs-40 fw-bold colorSecundary my-4">{traducir("Values", "Nuestros valores")}</h1>
            </div>
            <div class="row md:pb-5 md:mb-5 space-x-20">
              <div class="col-3 px-4 py-4 d-block text-center cardAboutItem ">
                <div class="pt-4 flex items-center justify-center">
                  <img src={Ray} alt="" />
                </div>
                <div class="py-4">
                  <b class="prussianBlue fs-22">{traducir("Innovation", "Innovación")}</b>
                </div>
              </div>
              <div class="col-3 px-4 py-4 d-block text-center cardAboutItem">
                <div class="pt-4 flex items-center justify-center">
                  <img src={Refresh} alt="" />
                </div>
                <div class="py-4">
                  <b class="prussianBlue fs-22">{traducir("Quality", "Mejoramiento")}</b>
                </div>
              </div>
              <div class="col-3 px-4 py-4 d-block text-center cardAboutItem">
                <div class="pt-4 flex items-center justify-center">
                  <img src={Star} alt="" />
                </div>
                <div class="py-4">
                  <b class="prussianBlue fs-22">{traducir("Commitment", "Compromiso")}</b>
                </div>
              </div>
              <div class="col-3 px-4 py-4 d-block text-center cardAboutItem">
                <div class="pt-4 flex items-center justify-center">
                  <img src={Users} alt="" />
                </div>
                <div class="py-4">
                  <b class="prussianBlue fs-22">{traducir("Empathy", "Empatía")}</b>
                </div>
              </div>
            </div>
            {/* ---------------- */}
            <div class="xl:hidden bg-[#ffffff60] rounded-br-[60px] p-4 ">
              <div className="flex">
                <div class="col-3 px-4 py-4 d-block text-center cardAboutItemMovil  border-r-2">
                  <div class="pt-4 flex items-center justify-center">
                    <img src={Ray} alt="" />
                  </div>
                  <div class="py-4">
                    <b class="prussianBlue fs-22">{traducir("Innovation", "Innovación")}</b>
                  </div>
                </div>
                <div class="col-3 px-4 py-4 d-block text-center cardAboutItemMovil">
                  <div class="pt-4 flex items-center justify-center">
                    <img src={Refresh} alt="" />
                  </div>
                  <div class="py-4">
                    <b class="prussianBlue fs-22">{traducir("Quality", "Mejoramiento")}</b>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div class="col-3 px-4 py-4 d-block text-center cardAboutItemMovil border-r-2 border-t-2">
                  <div class="pt-4 flex items-center justify-center">
                    <img src={Star} alt="" />
                  </div>
                  <div class="py-4">
                    <b class="prussianBlue fs-22">{traducir("Commitment", "Compromiso")}</b>
                  </div>
                </div>
                <div class="col-3 px-4 py-4 d-block text-center cardAboutItemMovil border-t-2">
                  <div class="pt-4 flex items-center justify-center">
                    <img src={Users} alt="" />
                  </div>
                  <div class="py-4">
                    <b class="prussianBlue fs-22">{traducir("Empathy", "Empatía")}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3"></div>
        </div>
      </section>
      <section className="mb-5" style={{ backgroundColor: "#E1F5FF" }}>
        <div class="bg-white my-auto " style={{ borderRadius: " 50% 0% 0% 0%" }}>
          <div class=" py-5 container items-center justify-center">
            <div class="text-center flex items-center justify-center">
              <img src={start} alt="" srcset="" />
            </div>
            <div class="text-center pt-5 pb-4">
              {traslate(
                <h1 class="fs-40 fw-bold colorSecundary">
                  Meet our <span class="colorYellow">team</span>
                </h1>,
                <h1 class="fs-40 fw-bold colorSecundary">
                  Conoce nuestro <span class="colorYellow">equipo</span>
                </h1>
              )}
            </div>
            <div class="text-center pb-5 md:w-[50%] m-auto py-4 flex">
              <p class="fs-20  colorGray">
                {traducir(
                  "We are a group of creative, committed, innovative and efficient people working together to achieve our mission.",
                  "Nuestro equipo es un grupo de personas creativas, eficientes, comprometidas e innovadoras que dejan parte de si mismos y nos ayudan a cumplir con nuestros objetivos."
                )}
              </p>
            </div>
            <div className="tabsTeamResponsive flex gap-3 flex-wrap items-center justify-center md:space-x-5 space-y-4 text-center">
              <button
                onClick={() => {
                  setOptionSelected({ ...optionSelected, option: "developer", selected: true });
                }}
                style={{
                  marginLeft:'0px'
                }}
                className={`${
                  optionSelected.option === "developer" ? "text-[#fff] bg-[#0783FF] rounded-[32px]" : "text-[#0783FF] "
                } btnAboutsUsTeam fw-bold fs-26-12 py-1 mt-3 p-0 w100md`}
              >
                {traducir("Development", "Desarrollo")}
              </button>
              <button
                onClick={() => {
                  setOptionSelected({ ...optionSelected, option: "UI/UX", selected: true });
                }}
                style={{
                  marginLeft:'0px'
                }}
                className={`${
                  optionSelected.option === "UI/UX" ? "text-[#fff] bg-[#0783FF] rounded-[32px]" : "text-[#0783FF] "
                } btnAboutsUsTeam fw-bold fs-26-12 py-1 p-0 w100md`}
              >
                UI/UX
              </button>
              <button
                onClick={() => {
                  setOptionSelected({ ...optionSelected, option: "req", selected: true });
                }}
                style={{
                  marginLeft:'0px'
                }}
                className={`${
                  optionSelected.option === "req" ? "text-[#fff] bg-[#0783FF] rounded-[32px]" : "text-[#0783FF] "
                } btnAboutsUsTeam fw-bold fs-26-12 py-1 p-0 w100md`}
              >
                {traducir("Requirements", "Requerimientos")}
              </button>
              <button
                onClick={() => {
                  setOptionSelected({ ...optionSelected, option: "junta", selected: true });
                }}
                style={{
                  marginLeft:'0px'
                }}
                className={`${
                  optionSelected.option === "junta" ? "text-[#fff] bg-[#0783FF] rounded-[32px]" : "text-[#0783FF] "
                } btnAboutsUsTeam fw-bold fs-26-12 py-1 p-0 w100md`}
              >
                {traducir("Board", "Junta directiva")}
              </button>
            </div>
            <div class="row pt-5 ">
              <Carousel
                breakPoints={breakPoints}
                outerSpacing={5}
                itemPadding={[0, 20]}
                showEmptySlots
                showArrows={true}
                pagination={true}
                enableMouseSwipe={true}
                // itemPosition={consts.CENTER}
              >
                {renderListUser()}
              </Carousel>
            </div>
            <div class="text-center mt-5">
              <a className="btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none" href="/trabajaConNosotros">
                <b>{traducir("Join our team", "Únete a nuestro equipo  ")}</b>
                <span className="mx-1 arrowLeft">ㅤ</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="md:mt-40 mb-20">
        <ContactForm />
      </div>
    </>
  );
};
export default AboutUs;
