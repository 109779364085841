import React from "react";
import Header from "../components/Header";
import Footer from "./Footer";

function FullLayout(props) {
  return (
    <div >
      <Header></Header>
      <section>
        {props.children}
      </section>
      
      <Footer></Footer>
    </div>
  );
}
export default FullLayout;
