import React, { useContext, useState } from "react";
import P1 from "../assets/TelemedPro.png";
import P2 from "../assets/biowelAdminPro.png";
import P3 from "../assets/biowelAsisPro.png";
import P4 from "../assets/vacPro.png";
import P5 from "../assets/vpocketPro.png";
import P6 from "../assets/actiFijoPro.png";
import P7 from "../assets/PhasePro.png";
import P8 from "../assets/sqPro.png";
import LogoMed from "../assets/logoTelemed.svg";
import LogoBioAdmin from "../assets/logoBioAdmi.svg";
import LogoBioAsis from "../assets/logoBioAsis.svg";
import LogoVac from "../assets/logoVac.svg";
import LogoVp from "../assets/logoVp.svg";
import LogoActiv from "../assets/logoActiv.svg";
import LogoPhase from "../assets/logoPhase.svg";
import LogoSq from "../assets/logoSq.svg";
import ContactForm from "./ContactForm";
import { store } from "./Context/store";
import start from "../assets/startIcon.svg";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
const Products = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  // console.log("prueba", window.screen.width )

  const [showBiowel, setShowBiowel] = useState({
    showBiowel: "",
  })


  const show = () => {
  if ( window.screen.width > 950 ) {
    setShowBiowel({...showBiowel, showBiowel: "/productos/biowel" })
    console.log("si")
  } else {
    setShowBiowel({...showBiowel, showBiowel: "/productos"})}
  }
  





  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }
  return (
    <>
      <section class="bannerProducts py-4">
        <div class="mx-md-5 h-100 px-4 px-md-5">
          <div class="row gx-5 h-100 align-items-end justify-content-start">
            <div class=" col-12">
              <div class="text-start my-5 py-5">
                <h1 class="display-5 fw-bold colorPrimary mb-2 fs-96">{traducir("Our work", "Nuestro trabajo")}</h1>
                <div className="col-md-8">
                  <span class="display-5  fw-bold colorSecundary mb-2 fs-40|">
                  {traducir(
                    "Learn how to enhance your projects with our services and solutions",
                    "Conoce como potencializar tu proyecto con nuestros servicios y soluciones"
                  )}
                </span>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="py-5 sectionSecundary bg-white ">
        <div class=" container flex flex-col items-center justify-center px-4 px-md-5 ">
          
          <div className="sm:hidden text-center flex flex-col justify-center items-center">
          <img src={start} alt="start" />
          <span className="text-[#003264] text-[24px] font-bold py-3">{traducir("Portfolio","Portafolio")}</span>
          </div>
          <div class="d-md-flex w-100 md:py-5 ">
            <div className="col-md-6 col-12 flex flex-col items-center justify-center my-4">
              <div className="text-center ">
                <img src={P1} alt="" />
              </div>
              <div className="text-center">
                <img src={LogoMed} alt="" />
              </div>
              <div className="text-center py-3">
                <p className="fw-bold colorSecundary">
                  {traducir("Attention of consultations by telemedicine", "Atención de consultas por telemedicina")}
                </p>
              </div>
              <div className="text-center mt-3">
                <a className="btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none" href="/productos">
                  <b>{traducir("Learn more", "Conoce más")}</b>
                  <span className="mx-1 arrowLeft">ㅤ</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12 flex flex-col items-center justify-center my-4">
              <div className="text-center">
                <img src={P2} alt="" />
              </div>
              <div className="text-center">
                <img src={LogoBioAdmin} alt="" />
              </div>
              <div className="text-center py-2">
                <p className="fw-bold colorSecundary">
                  {traducir("Corporate administration and fincance processes", "Administración y finanzas corporativas")}
                </p>
              </div>
              <a className="" onClick={()=> show()} href={`${showBiowel.showBiowel}`}>
              {/* <a className="" href="/productos/biowel"> */}
                <div className="text-center mt-3 btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none">
                  <b>{traducir("Learn more", "Conoce más")}</b>
                  <span className="mx-1 arrowLeft">ㅤ</span>
                </div>
              </a>
            </div>
          </div>
          <div class="d-md-flex w-100 md:py-5">
            <div className="col-md-6 col-12 flex flex-col items-center justify-center my-4">
              <div className="text-center ">
                <img src={P3} alt="" />
              </div>
              <div className="text-center ">
                <img src={LogoBioAsis} alt="" />
              </div>
              <div className="text-center py-4">
                <p className="fw-bold colorSecundary">
                  {traducir("assistance software for medical services ", "Software de asistencia, ordenamientos y más")}
                </p>
              </div>
              <div className="text-center  mt-3">
                <a className="btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none" href="/">
                  <b>{traducir("Learn more", "Conoce más")}</b>
                  <span className="mx-1 arrowLeft">ㅤ</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12 flex flex-col items-center justify-center my-4">
              <div className="text-center">
                <img src={P4} alt="" />
              </div>
              <div className="text-center mb-1">
                <img src={LogoVac} alt="" />
              </div>
              <div className="text-center py-3 mt-1">
                <p className="fw-bold colorSecundary">
                  {traducir(
                    "Subscription and follow up for vaccination processes ",
                    "Plataforma de inscripción y seguimiento de jornadas de vacunación."
                  )}
                </p>
              </div>
              <div className="text-center  mt-4">
                <a className="btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none" href="/">
                  <b>{traducir("Learn more", "Conoce más")}</b>
                  <span className="mx-1 arrowLeft">ㅤ</span>
                </a>
              </div>
            </div>
          </div>
          <div class="d-md-flex w-100 md:py-5">
            <div className="col-md-6 col-12 flex flex-col items-center justify-center my-4">
              <div className="text-center">
                <img src={P5} alt="" />
              </div>
              <div className="text-center">
                <img src={LogoVp} alt="" />
              </div>
              <div className="text-center py-3">
                <p className="fw-bold colorSecundary">{traducir("Credit card management", "Control de tarjetas de crédito")}</p>
              </div>
              <div className="text-center  mt-3">
                <a className="btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none" href="/">
                  <b>{traducir("Learn more", "Conoce más")}</b>
                  <span className="mx-1 arrowLeft">ㅤ</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12 flex flex-col items-center justify-center my-4">
              <div className="text-center">
                <img src={P6} alt="" />
              </div>
              <div className="text-center">
                <img src={LogoActiv} alt="" />
              </div>
              <div className="text-center py-3">
                <p className="fw-bold colorSecundary">{traducir("Fix assets management", "Sistematización de activos fijos")}</p>
              </div>
              <div className="text-center  mt-3">
                <a className="btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none" href="/">
                  <b>{traducir("Learn more", "Conoce más")}</b>
                  <span className="mx-1 arrowLeft">ㅤ</span>
                </a>
              </div>
            </div>
          </div>
          <div class="d-md-flex w-100 md:py-5">
            <div className="col-md-6 col-12 py-3 py-md-0 flex flex-col items-center justify-center my-4">
              <div className="text-center">
                <img src={P7} alt="" />
              </div>
              <div className="text-center mt-2">
                <img src={LogoPhase} alt="" />
              </div>
              <div className="text-center py-4">
                <p className="fw-bold colorSecundary">{traducir("Quality management software", "Software de gestión de calidad")}</p>
              </div>
              <div className="text-center  mt-3">
                <a className="btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none" href="/">
                  <b>{traducir("Learn more", "Conoce más")}</b>
                  <span className="mx-1 arrowLeft">ㅤ</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12 flex flex-col items-center justify-center my-4">
              <div className="text-center">
                <img src={P8} alt="" />
              </div>
              <div className="text-center">
                <img src={LogoSq} alt="" />
              </div>
              <div className="text-center py-3">
                <p className="fw-bold colorSecundary">
                  {traducir("Customer service automation software", "Software de automaticación de atención al cliente")}
                </p>
              </div>
              <div className="text-center  mt-3">
                <a className="btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none" href="/">
                  <b>{traducir("Learn more", "Conoce más")}</b>
                  <span className="mx-1 arrowLeft">ㅤ</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="md:mt-30 mb-20">
        <ContactForm />
      </div>
    </>
  );
};
export default Products;
