import React, { useContext, useState } from "react";
// import telemdIcon from "../assets/telemdIcon.svg"
// import LogoMed from "../assets/logoTelemed.svg";
import LogoBioAdmin from "../assets/logoBioAdmi.svg";
import biowelImg from "../assets/biowelLogo.png";
import biowelImg1 from "../assets/biowelImage1.svg";
import biowelImg2 from "../assets/biowelImage2.svg";
import biowelImg3 from "../assets/biowelImage3.svg";
import biowelImg4 from "../assets/biowelImage4.svg";
import biowelImg5 from "../assets/biowelImage5.svg";
import biowelImg6 from "../assets/biowelImage6.svg";
import biowelImg7 from "../assets/biowelImage7.svg";
import biowelImg8 from "../assets/biowelImage8.svg";
import gerencia from "../assets/gerencialSVG.svg";
import compras from "../assets/comprasSVG.svg";
import decoration from "../assets/decorationIMG.svg";
import checkbox from "../assets/biowelCheckbok.svg";
import check from "../assets/blueCheck.svg";
import foca from "../assets/focaLogo.svg";
import sena from "../assets/senaLogo.svg";
import colombia from "../assets/colombiaProductiva.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactForm from "./ContactForm";
import Carousel from "react-elastic-carousel";
import { store } from "./Context/store";

const Biowel = () => {
  const AccordionQuestions = () => {
    return (
      <div className="container md:flex items-start justify-center mb-10">
        <div className="col-md-6 col-12 flex flex-col mr-5 space-y-3">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <span>
                {traducir(
                  "My company's needs are different, how could you help?",
                  "¿La necesidad de mi empresa es diferente, cómo podrían ayudarme?"
                )}
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
              </span>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <span>{traducir("What type of support tools do you offer? ", "¿Qué tipo de soporte ofrece la herramienta?")}</span>
            </AccordionSummary>
            <AccordionDetails>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
              </span>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <span>{traducir("How much is the cost? ", "¿Cuanto podría costar el software independiente?")}</span>
            </AccordionSummary>
            <AccordionDetails>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
              </span>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <span>
                {traducir(
                  "Is it possible to link this software with those that already exist in our company?",
                  "¿Es posible vincular este software con los que existen actualmente en la empresa?"
                )}
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
              </span>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="col-md-6 col-12 flex flex-col space-y-3 justify-start items-start align-top">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <span>{traducir("What is your privacy policy?", "¿Qué tipo de privacidad ofrece el servicio?")}</span>
            </AccordionSummary>
            <AccordionDetails>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
              </span>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <span>{traducir("How can I get access to your products?", "¿Cómo puedo negociar acceso a este producto?")}</span>
            </AccordionSummary>
            <AccordionDetails>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
              </span>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <span>
                {traducir("Is there any limit to the number of users?", "¿Existe alguna limitación en número de usuarios o activos?")}
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
              </span>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <span>{traducir("Which devices are supported?", "¿Qué dispositivos tienen soporte?")}</span>
            </AccordionSummary>
            <AccordionDetails>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
              </span>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );
  };
  const globalState = useContext(store);
  const { state } = globalState;

  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false, showArrows: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 1 },
  ];

  const [companyResourceSelected, setCompanyResourceSelected] = useState({
    option: "management"
  })

  const companyResource= ()=>{

    let description = companyResourceSelected.option === "management" ?
    traducir("Entérate en tiempo real de lo que sucede con los informes estadísticos gerenciales.", "Entérate en tiempo real de lo que sucede con los informes estadísticos gerenciales.")
    :
    companyResourceSelected.option === "hr" ?
    traducir("Con el módulo de recursos humanos lleva la gestión de las nuevas contrataciones, los colaboradores que hacen parte de tu nómina y olvidate de procesos largos realizaos en papel.",
     "Con el módulo de recursos humanos lleva la gestión de las nuevas contrataciones, los colaboradores que hacen parte de tu nómina y olvidate de procesos largos realizaos en papel.")
    :
    companyResourceSelected.option === "acquisitions" ?
    traducir("Realiza la gestión de la adquisición de los recursos que necesites para tu empresa con el módulo de compras.",
     "Realiza la gestión de la adquisición de los recursos que necesites para tu empresa con el módulo de compras.")
    : ""
    



    return(
      <>
       <span className="fs-16 textDarkGray ">
                  {
                    description
                  }
                </span>
                {
                  companyResourceSelected.option === "management"?
                  <>
                  <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">{traducir("Monitor indicators", "Monitorización de indicadores por área.")}</span>
                </div>
                <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">{traducir("See results in real time", "Resultados en tiempo real.")}</span>
                </div>
                  </>
                  :
                  companyResourceSelected.option === "hr"?
                  <>
                  <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">{traducir("Realiza contrataciones y controla tu planilla de nómina. ", "Realiza contrataciones y controla tu planilla de nómina. ")}</span>
                </div>
                <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">{traducir("Programa exámenes médicos por área.", "Programa exámenes médicos por área.")}</span>
                </div>
                <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">{traducir("Registra y aprueba las novedades de tus empleados.", "Registra y aprueba las novedades de tus empleados.")}</span>
                </div>
                  </>
                  :
                  companyResourceSelected.option === "acquisitions"?
                  <>
                  <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">{traducir("Solicitud de requisiciones, compras y cotizaciones.", "Solicitud de requisiciones, compras y cotizaciones.")}</span>
                </div>
                <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">{traducir("Realiza Subastas, convenios y notas de proveedores.", "Realiza Subastas, convenios y notas de proveedores.")}</span>
                </div>
                <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">{traducir("Registra de entradas de almacén, monitorea tu inventario.", "Registra de entradas de almacén, monitorea tu inventario.")}</span>
                </div>
                  </>
                  :
                  <></>
                }
                
      </>
    )

  }

  
  return (
    <>
      <section className="bannerBiowel1 py-4 ">
        <div className="md:my-12 lg:my-6 px-4 h-100 px-md-5 md:flex items-center justify-center container">
          <div className="col-md-6 col-12 flex flex-col my-5">
            <div className="mt-5 sm:mt-4">
              <img src={LogoBioAdmin} alt="telemdIcon" className="h-[92px]" />
            </div>
            <div className="flex flex-col mt-8 ">
              <span className="fs-48 prussianBlue font-bold">
                {traducir(
                  "Drive your health entity into the future with our management software ERP ",
                  "Impulsa tu entidad de salud hacia el futuro con el software de gestión ERP"
                )}
              </span>
              <span className="fs-26 colorGray mt-2">
                {traducir(
                  "Modernize and centralize your company’s internal processes through one system. ",
                  "Moderniza y centraliza en un solo sistema los procesos internos de tu empresa."
                )}
              </span>
            </div>
            <div className="mt-5 space-x-5 md:flex flex-wrap ">
              <span className="telSpanBanner fs-16 py-1 px-3">{traducir("Management", " Administración")}</span>
              <span className="telSpanBanner fs-16 py-1 px-3"> {traducir("Accounting", "Contabilidad")}</span>
              <span className="telSpanBanner fs-16 py-1 px-3"> {traducir("resource management", "Gestión de recursos")}</span>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <img src={biowelImg} alt="biowelImg" className="  sm:h-[350px]"  />
          </div>
        </div>
      </section>
      <section className="container bg-white my-40">
        {/* BIOWEL INTRODUCTION */}
        <div className="bg-[#E1F5FF] my-8 md:flex">

          <div className=" col-md-5 col-12 flex flex-col py-5 pl-12 ">
            <span className="prussianBlueDark fs-26 font-bold"> {traducir("What is Biowel?", "¿Qué es biowel?")}</span>
            <span className="textDarkGray fs-24 mt-4">
              {traducir(
                "Tool designed for the management of the administrative processes of health institutions. ",
                "Es una herramienta diseñada para las entidades del sector salud, para la gestión de sus procesos financieros, administrativos y gerenciales."
              )}
            </span>
            <span className="textClearGray fs-16 mt-2">
              {traducir(
                "Biowel will simplify, automatize, and optimize the execution time of all of your internal activities; acquisitions, portfolio management, billing, and Human resource management through the monitoring of different indicators.",
                "Biowel te permitirá simplificar, automatizar y optimizar los tiempos de ejecución de todas tus actividades internas; desde tu compras, manejo de cartera, facturación hasta manejo de recursos humanos y el seguimiento de indicadores."
              )}
            </span>
            <div className="mt-5">
              <button className="bioContactButton fs-14 py-1 px-5">{traducir("Contact us", "Contáctanos")}</button>
            </div>
          </div>
          {/* )} */}

          <div className="col-md-7 col-12 md:relative ">
            <img alt="image1" src={biowelImg1} className=" md:absolute -right-10 -top-10 drop-shadow-xl" />
            <img alt="image2" src={biowelImg2} className=" md:absolute -bottom-10 drop-shadow-xl" />
          </div>
        </div>
        {/* ADVANTAGE */}
        <div className=" md:flex flex-col items-center justify-center my-24">
          {traslate(
            <div className="flex flex-col text-center">
              <span className="textDarkGray fs-26 font-bold">Designed for the health sector</span>
              <span className="prussianBlueDark fs-38 font-bold">Benefits</span>
            </div>,
            <div className="flex flex-col text-center">
              <span className="textDarkGray fs-26 font-bold">Diseñado para el sector salud</span>
              <span className="prussianBlueDark fs-38 font-bold">Ventajas de biowel</span>
            </div>
          )}

          <div className="md:flex my-4 justify-between w-full">
          <Carousel breakPoints={breakPoints} showArrows={false}>
            <div className="flex flex-col items-center justify-center rounded-md  bg-white border-2 drop-shadow-xl md:h-[445px] md:w-[400px]">
              <img src={biowelImg3} alt="" className="md:h-[184px] mt-[22px]" />
              {traslate(
                <p className="m-3 flex flex-col text-center pt-8">
                  <span className="colorSecundary fs-24 md:text-[20px] font-bold "> Take control of your finances</span>
                  <span className="textClearGray fs-16-10">
                    Ensure the payment of your customers, suppliers, and employees through an automated process.
                  </span>
                </p>,
                <p className="m-3 flex flex-col text-center pt-8">
                  <span className="colorSecundary fs-24  md:text-[20px] font-bold "> Lleva el control de tus finanzas</span>
                  <span className="textClearGray fs-16-10">
                    Asegura el pago de tus clientes, proveedores y empleados, a través de un proceso automatizado
                  </span>
                </p>
              )}
            </div>
            <div className="flex flex-col items-center justify-center rounded-md bg-white border-2 drop-shadow-xl md:h-[445px] md:w-[400px]">
              <img src={biowelImg4} alt="" className="md:h-[184px] " />
              {traslate(
                <p className="m-3 flex flex-col text-center pt-8">
                  <span className="colorSecundary fs-24 md:text-[20px] font-bold "> Organize and structure your work</span>
                  <span className="textClearGray fs-16-10">
                    reduce the number of steps and create a more comfortable workflow for you and your team.{" "}
                  </span>
                </p>,
                <p className="m-3 flex flex-col text-center pt-8">
                  <span className="colorSecundary fs-24 md:text-[20px] font-bold "> Organiza tu estructura de trabajo</span>
                  <span className="textClearGray fs-16-10">
                    Para reducir la cantidad de pasos y crea un flujo de trabajo más cómodo para ti y tu equipo de trabajo
                  </span>
                </p>
              )}
            </div>
            <div className="flex flex-col items-center justify-center rounded-md bg-white border-2 drop-shadow-xl md:h-[445px] md:w-[400px]">
              <img src={biowelImg5} alt="" className="md:h-[184px] " />
              {traslate(
                <p className="m-3 flex flex-col text-center pt-8">
                  <span className="colorSecundary fs-24 md:text-[20px] font-bold "> Simplify and avoid reprocesses</span>
                  <span className="textClearGray fs-16-10">decrease deadlocks, errors and loss of valuable information</span>
                </p>,
                <p className="m-3 flex flex-col text-center pt-8">
                  <span className="colorSecundary fs-24 font-bold "> Simplifica y evita reprocesos</span>
                  <span className="textClearGray fs-16-10">Disminuye los estancamientos, errores y pérdida de información valiosa </span>
                </p>
              )}
            </div>
            </Carousel>
          </div>
        </div>
        {/* APPLICATIONS */}
        <div className="flex flex-col">
          {traslate(
            <div className="flex flex-col text-center">
              <span className="fs-26 textDarkGray font-bold">APPs</span>
              <span className="fs-38 prussianBlueDark font-bold">Centralize internal processes with a single system</span>
            </div>,
            <div className="flex flex-col text-center">
              <span className="fs-26 textDarkGray font-bold">Aplicaciones</span>
              <span className="fs-38 prussianBlueDark font-bold">Centraliza en un solo sistema los procesos internos</span>
            </div>
          )}

          <div className=" md:flex ">
            <div className="col-md-6 col-12 relative">
              <img src={decoration} alt="" className="absolute left-20 top-5" />
              <img src={biowelImg6} alt="" className="w-[591px]" />
              <img src={decoration} alt="" className="absolute right-20 -bottom-24" />
            </div>
            <div className="col-md-6 col-12 md:flex items-center justify-center">
              <div className=" col-md-6 mx-4 col-12 space-y-10 ">
                <div className=" flex  space-x-4 bg-white drop-shadow-xl p-4 ">
                  <img src={checkbox} alt="checkbox" />
                  <span className=" self-center fs-18 textDarkGray font-bold inline-block  align-middle">
                    {traducir("Acquisitions and inventory ", "Compras e inventario")}
                  </span>
                </div>
                <div className=" flex  space-x-4 bg-white drop-shadow-xl p-4 ">
                  <img src={checkbox} alt="checkbox" />
                  <span className=" self-center fs-18 textDarkGray font-bold inline-block  align-middle">
                    {traducir("Accounting and finance ", "Contabilidad y finanzas")}
                  </span>
                </div>
                <div className=" flex  space-x-4 bg-white drop-shadow-xl p-4 ">
                  <img src={checkbox} alt="checkbox" />
                  <span className=" self-center fs-18 textDarkGray font-bold inline-block  align-middle">
                    {traducir("HR", "Recursos humanos")}
                  </span>
                </div>
              </div>
              <div className=" col-md-6 mx-4 col-12 space-y-10">
                <div className=" flex  space-x-4 bg-white drop-shadow-xl p-4 ">
                  <img src={checkbox} alt="checkbox" />
                  <span className=" self-center fs-18 textDarkGray font-bold inline-block  align-middle">
                    {traducir("Wallet", "Cartera")}
                  </span>
                </div>
                <div className=" flex  space-x-4 bg-white drop-shadow-xl p-4 ">
                  <img src={checkbox} alt="checkbox" />
                  <span className=" self-center fs-18 textDarkGray font-bold inline-block  align-middle">
                    {traducir("Billing", "Facturación")}
                  </span>
                </div>
                <div className=" flex  space-x-4 bg-white drop-shadow-xl p-4 ">
                  <img src={checkbox} alt="checkbox" />
                  <span className=" self-center fs-18 textDarkGray font-bold inline-block  align-middle">
                    {traducir("Management", "Gerencia general")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ADMINISTRATION */}
        <div className="flex flex-col my-40">
          {traslate(
            <div className="flex flex-col text-center">
              <span className="fs-26 textDarkGray font-bold">Management</span>
              <span className="fs-38 prussianBlueDark font-bold">Control your company resources</span>
            </div>,
            <div className="flex flex-col text-center">
              <span className="fs-26 textDarkGray font-bold">Administración</span>
              <span className="fs-38 prussianBlueDark font-bold">Controla tus recursos empresariales</span>
            </div>
          )}

          <div className="md:flex mt-5">
            <div className="col-md-6 col-12 ">
              <div className="col-md-9 mx-5">
                {" "}
                <span className="fs-32 textDarkGray "
                onClick={() => {setCompanyResourceSelected({...companyResourceSelected, option: "management"})}}
                >
                  {traducir(
                    "Learn about the status of your resources, your inventory and your collaborations with biowel. ",
                    "Conoce el estado actual de tus recursos, tu inventario y tus colaboradores con biowel"
                  )}
                </span>
                {/* ANCHOR COMPANIE */}
                  <div className="fs-15 space-x-5 border-b-4 py-3 mt-5 mb-3">
                    <span 
                    className={`${companyResourceSelected.option === "management" ? "colorPrimary border-b-4 py-3 border-[#01a0f6]":
                  "prussianBlueDark cursorPointer"
                  }  cursorPointer transition ease-in-out delay-150`}
                    onClick={() => {setCompanyResourceSelected({...companyResourceSelected, option: "management"})}}

                    >{traducir("MANAGEMENT", "GERENCIAL")}  </span>

                    <span 
                    className={`${companyResourceSelected.option === "hr" ? "colorPrimary border-b-4 py-3 border-[#01a0f6]":
                    "prussianBlueDark cursorPointer"
                    }  cursorPointer transition ease-in-out delay-150`}
                    // className="prussianBlueDark cursorPointer"
                    onClick={() => {setCompanyResourceSelected({...companyResourceSelected, option: "hr"})}}

                    >{traducir("HR", "RECURSOS HUMANOS")}</span>
                    <span
                    className={`${companyResourceSelected.option === "acquisitions" ? "colorPrimary border-b-4 py-3 border-[#01a0f6]":
                    "prussianBlueDark cursorPointer"
                    }  cursorPointer transition ease-in-out delay-150`}
                    // className="prussianBlueDark cursorPointer"
                    onClick={() => {setCompanyResourceSelected({...companyResourceSelected, option: "acquisitions"})}}

                    >{traducir("ACQUISITIONS AND INVENTORY", "COMPRAS E INVENTARIOS")}</span>
                  </div>
                {companyResource()}
              </div>
            </div>
            <div className="col-md-6 col-12 flex items-center justify-center">
              <img src={companyResourceSelected.option === "management" ? biowelImg7 : companyResourceSelected.option === "hr" ? gerencia :
            companyResourceSelected.option === "acquisitions" ? compras : compras} alt="" className="drop-shadow-xl" />
            </div>
          </div>
        </div>
        {/* FINANCE */}
        <div className="flex flex-col my-30">
          {traslate(
            <div className="flex flex-col text-center">
              <span className="fs-26 textDarkGray font-bold">Finances</span>
              <span className="fs-38 prussianBlueDark font-bold">Systematize your financial processes</span>
            </div>,
            <div className="flex flex-col text-center">
              <span className="fs-26 textDarkGray font-bold">Finanzas</span>
              <span className="fs-38 prussianBlueDark font-bold">Sistematiza tus procesos financieros</span>
            </div>
          )}

          <div className="md:flex mt-5">
            <div className="col-md-6 col-12 flex items-center justify-center">
              <img src={companyResource.option === "management" ? biowelImg8 : companyResource.option === "management" ? gerencia :
            companyResource.option === "management" ? compras : compras} alt="" className="drop-shadow-xl" />
            </div>
            <div className="col-md-6 col-12  ">
              <div className="col-md-9 mx-5">
                <span className="fs-32 textDarkGray ">
                  {traducir(
                    "Get automated reports without the need for spreadsheets",
                    "Registra y obtén reportes automatizados sin necesidad de hojas de cálculo "
                  )}
                </span>
                {traslate(
                  <div className="fs-15 space-x-5 border-b-4 py-3 mt-5 mb-3">
                    <span className="colorPrimary border-b-4 py-3 border-[#01a0f6]">ACCOUNTING</span>

                    <span className="prussianBlueDark">WALLET</span>
                    <span className="prussianBlueDark">TREASURY</span>
                    <span className="prussianBlueDark">BILLING</span>
                  </div>,
                  <div className="fs-15 space-x-5 border-b-4 py-3 mt-5 mb-3">
                    <span className="colorPrimary border-b-4 py-3 border-[#01a0f6]">CONTABILIDAD</span>

                    <span className="prussianBlueDark">CARTERA</span>
                    <span className="prussianBlueDark">TESORERIA</span>
                    <span className="prussianBlueDark">FACTURACIÓN</span>
                  </div>
                )}

                <span className="fs-16 textDarkGray ">
                  {traducir(
                    "Get all of your company's accounting done in one site and get 100% reliable reports",
                    "Lleva toda la contabilidad de tu empresa desde un solo sitio y obtén informes contables 100% fiables."
                  )}
                </span>
                <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">
                    {traducir("Decrease errors and reprocesses ", "Disminuye los errores y reprocesos")}
                  </span>
                </div>
                <div className=" flex space-x-4 mt-3">
                  <img src={check} alt="" />
                  <span className="prussianBlueDark">
                    {traducir("Information traceability ", "Correcto registro y trazabilidad de la información")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex flex-col text-center mb-4">
          <span className="fs-26 textDarkGray font-bold">F.A.Q.</span>
          <span className="fs-38 prussianBlueDark font-bold">{traducir("Frequent questions", "Preguntas frecuentes")}</span>
        </div>
        <AccordionQuestions />
      </section>
      <section>
        <div className="flex flex-col my-20 container justify-center items-center space-y-2">
          {traslate(
            <div className="flex flex-col text-center ">
              <span className="fs-26 textDarkGray font-bold">Award winning project</span>
              <span className="fs-38 prussianBlueDark font-bold">Developed with the support of</span>
            </div>,
            <div className="flex flex-col text-center ">
              <span className="fs-26 textDarkGray font-bold">Proyecto ganador.</span>
              <span className="fs-38 prussianBlueDark font-bold">Desarrollado con el apoyo de</span>
            </div>
          )}

          <div className="md:flex flex-wrap space-x-10 justify-center">
            <img className="" src={foca} alt="" />
            <img className="justify-self-center flex" src={sena} alt="" />
            <img className="" src={colombia} alt="" />
          </div>
        </div>
      </section>
      <ContactForm/>
    </>
  );
};

export default Biowel;
