import React, { useContext } from "react";
import ContactForm from "./ContactForm";
import { store } from "./Context/store";

const Contact = () => {
  const globalState = useContext(store);
  const { state } = globalState;

  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }

  return (
    <div classNameName="">
      <div> ‍‍‍‍‍ㅤ</div>
      <section className="bannerContactMovil py-4 ">
        <div className="md:mx-5 px-4 pb-5 flex gx-5 h-100 align-items-end justify-content-center  pt-6">
          {traslate(
            <div className="text-center my-5 md:flex flex-column ">
              <h1 className="display-5 fw-bold colorPrimary mb-2 fs-96 ">Contact us!</h1>
            </div>,
            <div className="text-center my-5 md:flex flex-column ">
              <h1 className="display-5 fw-bold colorPrimary mb-2 fs-96 row">¡Contáctanos!</h1>
            </div>
          )}
        </div>
      </section>
      <ContactForm classNameName=" absolute " />
    </div>
  );
};

export default Contact;
