import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import close from "../assets/closeButton.svg";
import home from "../assets/homeIcon.svg";
import usIcon from "../assets/usIcon.svg";
import services from "../assets/servicesIcon.svg";
import trabaja from "../assets/workWith.svg";
import contact from "../assets/contactUs.svg";
import products from "../assets/productos.svg";
// import WorkWithUs from "./WorkWithUs";
import world from "../assets/world.svg";
// import spanish from "../assets/spanishIcon.svg";
// import euu from "../assets/flag.svg";
import { store } from "./Context/store";

export default function Drawer({ children, isOpen, setIsOpen }) {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [optionSelected, setOptionSelected] = useState({
    option: "",
  });
  // console.log("state: ", state);
  const handlChangeLang = () => {
    dispatch({
      type: "CHANGE_LANGUAGE",
      payload: {
        english: !state.english,
      },
    });
  };

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }

  return (
    <main
      className={
        " fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen ? " transition-opacity opacity-100 duration-500 translate-x-0 " : " transition-all delay-500 opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          " w-screen max-w-[300px] right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative w-screen max-w-lg pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
          <header className="p-4 font-bold text-lg flex">
            <img src={close} alt="closeButton" onClick={() => setIsOpen(false)} className="mr-5" />
            {/* <img src={spanish} alt="icon" /> */}
          </header>
          <div className="flex flex-col ml-4 fs-16 text-[#01A2F5] font-bold space-y-2">
            <div onClick={() => handlChangeLang()} className=" text-center flex items-start justify-start ml-6 ">
              <span className=" me-sm-3 colorSecundary text-decoration-none flex space-x-2 cursorPointer">
                {traslate(<img src={world} alt="" className="mr-7" />, <img src={world} alt="" className="mr-7" />)}
                <b>{state?.english ? "ES" : "EN"}</b>
              </span>
            </div>
            <Link
              to="/"
              onClick={() => {
                setIsOpen(false);
                setOptionSelected({ ...optionSelected, option: "Home" });
              }}
              className="flex space-x-4"
            >
              <div
                className={`${
                  optionSelected.option === "Home" ? " bg-[#9ADCFF33] rounded-[8px]" : " border-0  "
                } flex space-x-9 py-2 pl-4 pr-20`}
              >
                <img src={home} alt="home" className="ml-1" /> <span>{state?.english ? "Home" : "Inicio"}</span>
              </div>
            </Link>
            <Link
              to="/nosotros"
              onClick={() => {
                setIsOpen(false);
                setOptionSelected({ ...optionSelected, option: "aboutUs" });
              }}
              className="flex space-x-4"
            >
              <div
                className={`${
                  optionSelected.option === "aboutUs" ? " bg-[#9ADCFF33] rounded-[8px]" : " border-0  "
                } flex space-x-8 py-2 pl-4 pr-20`}
              >
                <img src={usIcon} alt="aboutUs"  /> <span>{state?.english ? "About us" : "Nosotros"}</span>
              </div>
            </Link>

            <Link
              to="/servicios"
              onClick={() => {
                setIsOpen(false);
                setOptionSelected({ ...optionSelected, option: "services" });
              }}
              className="flex space-x-4"
            >
              <div
                className={`${
                  optionSelected.option === "services" ? " bg-[#9ADCFF33] rounded-[8px]" : " border-0  "
                } flex space-x-10 py-2 pl-4 pr-20`}
              >
                <img src={services} alt="services" /> <span>{state?.english ? "Services" : "Servicios"}</span>
              </div>
            </Link>
            <Link
              to="/Productos"
              onClick={() => {
                setIsOpen(false);
                setOptionSelected({ ...optionSelected, option: "products" });
              }}
              className="flex space-x-4"
            >
              <div
                className={`${
                  optionSelected.option === "products" ? " bg-[#9ADCFF33] rounded-[8px]" : " border-0  "
                } flex space-x-10 py-2 pl-4 pr-20`}
              >
                <img src={products} alt="products" className="ml-1"/> <span>{state?.english ? "Products" : "Productos"}</span>
              </div>
            </Link>

            <Link
              to="/trabajaConNosotros"
              onClick={() => {
                setIsOpen(false);
                setOptionSelected({ ...optionSelected, option: "Careers" });
              }}
              className="flex space-x-4"
            >
              <div
                className={`${
                  optionSelected.option === "Careers" ? " bg-[#9ADCFF33] rounded-[8px]" : " border-0  "
                } flex space-x-9 py-2 pl-4 pr-20`}
              >
                <img src={trabaja} alt="Careers" />
                <span>{state?.english ? "Careers" : "Trabaja con nosotros"}</span>
              </div>
            </Link>

            <Link
              to="/contactanos"
              onClick={() => {
                setIsOpen(false);
                setOptionSelected({ ...optionSelected, option: "contact" });
              }}
              className="flex space-x-4"
            >
              <div
                className={`${
                  optionSelected.option === "contact" ? " bg-[#9ADCFF33] rounded-[8px]" : " border-0  "
                } flex space-x-9 py-2 pl-4 pr-20`}
              >
                <img src={contact} alt="contact" className="ml-1"/>
                <span>{state?.english ? "Contact us" : "Contáctanos"}</span>
              </div>
            </Link>
          </div>
          {children}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
}
