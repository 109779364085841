import React, { useContext, useReducer, useState } from "react";
import A1 from "../assets/1.svg";
import A2 from "../assets/2.svg";
import A3 from "../assets/3.svg";
import B2 from "../assets/4.png";
import C1 from "../assets/money.svg";
import C2 from "../assets/cap.svg";
import C3 from "../assets/seg.svg";
import C4 from "../assets/reg.svg";
import D1 from "../assets/CirculitoPr.svg";
import D2 from "../assets/Circulito2pro.svg";
import D3 from "../assets/Circulito3.svg";
import Word from "../assets/word.svg";
import E1 from "../assets/5.png";
import arrowLeftBlue from "../assets/arrowLeftBlue.svg";
// import Model from "../assets/model.svg";
import Carousel from "react-elastic-carousel";
import ContactForm from "./ContactForm";
import { store } from "./Context/store";
import Typed from "react-typed";

const Home = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;

  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function translate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false, showArrows: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 1 },
  ];

  const [optionSelected, setOptionSelected] = useState({
    option: "",
    showDetail: false,
  });

const cardItems = ()=>{
// let tempArray = [];
let items = [
  { tittle: state?.english ? "Software development " : "Desarrollamos software" ,
    icon: A1,
    Text: translate(
      <p className=" text-[#003264]">
        Our specialty and reason to exist. TTN has an expert team in software development, capable of creating, improving or
        implementing any product for health related companies.{" "}
      </p>,
      <p className=" text-[#003264]">
        Nuestra especialidad y razón de ser. TTN cuenta con un equipo experto en desarrollo de software, capaz de crear, mejorar o
        implementar cualquier producto para entidades de salud.
      </p>
    )
  },
  { tittle: state?.english ? "Implementation partnerships" : "Socios de ejecución" ,
    icon: A2,
    Text: translate(
      <p className=" text-[#003264] transition ease-in-out delay-150">
        TTN has a team of experts in innovation, development and research to obtain resources, transformation and automation
        of your ideas.{" "}
      </p>,
      <p className=" text-[#003264] transition ease-in-out delay-150">
        TTN cuenta con un equipo de expertos en innovación, desarrollo e investigación para la consecución de recursos,
        transformación y automatización de tus ideas.{" "}
      </p>
    )
  },
  { tittle: state?.english ? "Product design" : "Diseñamos productos" ,
    icon: A1,
    Text: translate(
      <p className=" text-[#003264]">
        TTN has several customizable software products, created to alleviate the most common pains and renew the processes of
        the industry.
      </p>,
      <p className=" text-[#003264]">
        TTN cuenta con varios productos de software personalizables, creados para aliviar los dolores más comunes y renovar
        las procesos del sector{" "}
      </p>
    )
  },



]



const tempArray = items.map((item, index) =>{
  let hasBorder= optionSelected.option === index ? "border-2 border-[#0783FF]" : ""
return (
  <div className={`drop-shadow-sm  cursor-pointer ${hasBorder} rounded-2xl py-5 px-4 self-center transition-all		delay-150 duration-150
  group
  `} key={index}
  onClick={()=>{
    setOptionSelected({...optionSelected, option: optionSelected.option === index ? "" : index,})
  }}
  >
    <div className="flex flex-column ">
      <div className="text-center d-flex justify-center">
      <img alt="icon" src={item.icon} />
    </div>
    <div className="text-center">
      <b className="colorGray fs-26 group-hover:text-[#0783FF] mt-5"
      >{item.tittle}</b>
      <p className=" text-[#003264] transition-all		delay-150 duration-200 ">
{optionSelected.option === index &&
        item.Text
 }
      </p>
     
    </div>
    </div>
    
  </div>
);
})

return tempArray
}

  
  return (
    <>
      <section className="bannerHome">
        <div className="h-100 md:mx-5 px-4 px-md-5">
          <div className="h-100 justify-content-start">
            <div className="h-100 col-lg-6 col-12 mb-5">
              <div className="text-start py-[130px]">
                <h1 className="display-5 fw-bolder colorSecundary mb-2 fs-64  ">
                  <span className="typed "></span>
                  {translate(
                    <Typed strings={["Software", "Innovation", "Technology"]} typeSpeed={150} backSpeed={100} loop />,
                    <Typed strings={["Software", "Innovación", "Tecnología"]} typeSpeed={150} backSpeed={100} loop />
                  )}
                </h1>
                <h1 className="display-5 fw-bolder colorPrimary mb-2 fs-64">
                  {state?.english ? "Transforming the health industry" : "transformando el sector salud"}
                </h1>
                <p className="colorSecundary fw-bold fs-32 mb-4">
                  {state?.english
                    ? "We focus on modernizing the technological and software processes for clinics and hospitals around the world"
                    : "Modernizamos los procesos de clínicas y hospitales en todo el mundo"}
                </p>
                <div className="d-grid gap-3 d-sm-flex btnLearnMoreResponsive">
                  <a className="d-flex gap-2 btnSecundary py-2 px-4 me-sm-3 colorSecundary text-decoration-none btnLearnMoreResponsive" href="/nosotros">
                    <b>{state?.english ? "Learn more" : "Conoce más"}</b> 
                    {/* <span className="mx-1 arrowLeft">ㅤ</span> */}
                    <img 
                      src={arrowLeftBlue} 
                      alt="visitar enlace"
                      width={10}
                      height={10}
                      className='mx-1'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`py-4 sectionSecundary ${!state.darkMode ? "bg-white" : "bg-dark"}`}>
        <div className=" py-md-4 pt-4 container flex flex-col items-center justify-center">
          <div className="col-12 text-center mt-3">
            <span className="fw-bold fs-32-30 colorSecundary">
              {state?.english ? "Boost your projects towards the " : "Impulsa tus proyectos al "}
              <span className="colorYellow">{state?.english ? "future" : "futuro"}</span>
            </span>
          </div>
          <div className="row d-block d-md-flex mt-md-4 pt-4">
            <div className="grid md:grid-cols-3 md:gap-3 grid-cols-1 px-2 md:px-0">
               {cardItems()}
            </div>
            <div className="col-12 mt-5 text-center">
              <a className="btnSecundary py-2 px-5 me-sm-3 colorSecundary text-decoration-none" href="/servicios">
                <b>{state?.english ? "Our services" : "Nuestros servicios"}</b>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="p-5" style={{ backgroundColor: "#61FFF605" }}>
        {/* <!-- <section className="p-5" style="background-color: red;"> --> */}
        <div className="h-100  bg-white containerImg container">
          <div className="h-100 row">
            <div className="col-md-6 p-0  justify-center">
              <img className="md:h-full md:w-[750px] object-cover imgBR" src={B2} alt="" />
            </div>
            <div className="col-md-6 col-12 py-4 pt-5 text-center p-12 flex flex-column items-center">
              <div className="md:mx-5">
                <div className=" md:px-5 text-start flex items-start">
                  {translate(
                    <h1 className="  fs-40 fw-bold PrussianBlue120 text-start">
                      We understand you
                      <span className="prussianBlue"> better</span> than anyone
                    </h1>,
                    <h1 className=" fs-40 fw-bold PrussianBlue120 text-start">
                      Te entendemos
                      <span className="prussianBlue"> mejor</span> que nadie
                    </h1>
                  )}
                </div>
                <div className="md:px-5 my-5 flex justify-start self-start">
                  <img src={Word} alt="" className=" " />
                  <b className="prussianBlue fs-24 text-start pl-2">{traducir("World reference", "Referente mundial")}</b>
                </div>
                <div className="pr-md-5 md:px-4 fs-16">
                  <p className="text-justify">
                    {traducir("TTN Company was created by the", "TTN Company fue creada por la")}
                    <b> Clínica Oftalmológica del Caribe</b>,{" "}
                    {traducir(
                      "with whom we collaborate closely. This has made us experts at understanding the needs that arise when it comes to health related services.",
                      "un referente en toda Latinoamérica que nos brinda                  un entendimiento profundo de las necesidades de las empresas de salud, y también ofrece su apoyo conjunto, funcionando como un avanzado laboratorio de pruebas para nuestra tecnología."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="pb-5" style={{ backgroundColor: "#E1F5FF", borderRadius: "0% 0% 50% 0%" }}>
          <div className=" container flex flex-col items-center justify-center">
            <div className="text-center py-5">
              <h1 className="fs-40 fw-bold colorSecundary">
                {traducir("Centralize your business ", "Centraliza todos")}
                <br />
                {traducir("", "los")}
                <span className="prussianBlue">{traducir("processes", " procesos")}</span>
                {traducir("", " de tu empresa")}
              </h1>
            </div>
            <div className="pb-5 mb-5 text-center md:space-x-28 space-y-5 contents">
              <Carousel breakPoints={breakPoints} showArrows={false}>
                <div className="col-md-3 px-4 py-5 d-md-block text-center cardItem flex flex-col items-center">
                  <div className="pt-4 flex items-center justify-center">
                    <img src={C1} alt="" className="" />
                  </div>
                  <div className="py-5">
                    <b className="prussianBlue fs-24">{traducir("Accounting", "Contabilidad")}</b>
                  </div>
                </div>
                <div className="col-md-3 px-4 py-5 d-block text-center cardItem  flex flex-col items-center">
                  <div className="pt-4 flex items-center justify-center">
                    <img src={C2} alt="" />
                  </div>
                  <div className="py-5">
                    <b className="prussianBlue fs-24">{traducir("Attendance", "Asistencia")}</b>
                  </div>
                </div>
                <div className="col.md-3 px-4 py-5 d-block text-center cardItem">
                  <div className="pt-4 flex items-center justify-center">
                    <img src={C3} alt="" />
                  </div>
                  <div className="py-5 flex items-center justify-center">
                    <b className="prussianBlue fs-24">{traducir("Quality", "Calidad")}</b>
                  </div>
                </div>
                <div className="col-md-3 px-4 py-5 d-block text-center cardItem">
                  <div className="pt-4 flex items-center justify-center">
                    <img src={C4} alt="" />
                  </div>
                  <div className="py-5">
                    <b className="prussianBlue fs-24">{traducir("Records", "Registro")}</b>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5 p-2" style={{ backgroundColor: " #E1F5FF" }}>
        <div className="bg-white my-auto" style={{ borderRadius: " 50% 0% 0% 0%" }}>
          <div className=" py-5 container flex flex-col items-center justify-center">
            <div className="text-center py-5">
              <h1 className="fs-40 fw-bold colorSecundary">
                {traducir("We want to contribute to the world through ", "Queremos aportar al mundo")}
                <br /> {traducir("", "con nuestro")} <span className="prussianBlue">{traducir("our service", "servicio")}</span>
              </h1>
            </div>
            <div className="md:flex  items-center justify-center flex-wrap">
              <div className="col d-flex py-8 ">
                <div className="align-self-center" style={{ marginRight: "-55px", zIndex:"100" }}>
                  <img src={D1} alt="" />
                </div>
                <div className="w-[337px] p-2 prussianBlue " style={{ border: "solid 1px #01a0f6", borderRadius: "10px" }}>
                  <div className="text-center">
                    <span className="fw-bold fs-40">+400.000</span>
                  </div>
                  <div className="text-center">
                    <span className="fw-bold colorSecundary fs-18">{traducir("Registered members", "Usuarios registrados")}</span>
                  </div>
                </div>
              </div>
              <div className="col d-flex py-8">
                <div className="align-self-center" style={{ marginRight: "-55px", zIndex:"100" }}>
                  <img src={D2} alt="" style={{ fillOpacity: 0.25 }} />
                </div>
                <div className="w-[337px] p-2  prussianBlue" style={{ border: "solid 1px #01a0f6", borderRadius: "10px" }}>
                  <div className="text-center">
                    <span className="fw-bold fs-40 px-5">+100</span>
                  </div>
                  <div className="text-center">
                    <span className="fw-bold colorSecundary fs-18">{traducir("Automated operations", "Operaciones automatizadas")}</span>
                  </div>
                </div>
              </div>
              <div className="col d-flex py-8">
                <div className="align-self-center" style={{ marginRight: "-55px", zIndex:"100" }}>
                  <img src={D3} alt="" />
                </div>
                <div className="w-[337px] p-2  prussianBlue" style={{ border: "solid 1px #01a0f6", borderRadius: "10px" }}>
                  <div className="text-center">
                    <span className="fw-bold fs-40">+500.000</span>
                  </div>
                  <div className="text-center">
                    <span className="fw-bold colorSecundary fs-18">{traducir("Daily movements", "Movimientos al día")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className=" w-100 container p-0 md:flex items-center justify-center responsiveSectPrivacy">
          <div className="col-md-6 col-12 px-5 md:py-4 pt-5 privacyDiv">
            <div className="md:px-5 text-start fwBold ">
              {translate(
                <h1 className="fs-40 fw-bold colorSecundary">
                  <span className="colorYellow"> Privacy </span> and support guaranteed
                </h1>,
                <h1 className="fs-40 fw-bold colorSecundary">
                  Garantizamos
                  <span className="colorYellow"> privacidad</span> y soporte
                </h1>
              )}
            </div>
            <div className="md:px-5 mt-5 fwBold">
              {translate(
                <p className="text-justify sm:text-[#888FAA] text-[#003264] fs-24-18 pb-5">
                  The health sector deals with extremely sensitive information with unmatched volume and complexity.{" "}
                </p>,
                <p className="text-justify sm:text-[#888FAA] text-[#003264] fs-24-18 pb-5">
                  Sabemos de primera mano que el sector salud maneja información delicada en un volumen y complejidad incomparable.
                </p>
              )}

              {translate(
                <p className="text-justify sm:text-[#888FAA] text-[#003264] fs-24">
                  Hence, our technology is designed to manage data, prioritizing privacy, and stability in our services to keep your
                  operations running without the need to worry about the system.
                </p>,
                <p className="text-justify sm:text-[#888FAA] text-[#003264] fs-24">
                  Es por ello que nuestra tecnología esta diseñada para el manejo de este tipo de datos, priorizando la privacidad y
                  estabilidad del servicio para mantener tus operaciones sin necesidad de preocuparte por el sistema.
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12 p-0  text-end flex items-end justify-end imgDiv">
            <img className=" imgBRL300 " alt="" src={E1} width="" height="auto" />
          </div>
        </div>
      </section>
      {/* <section className="pt-5 container">
        <div className="row">
          <div className="text-center pb-4">
            <span className="prussianBlueDark fs-40 fw-bold">{traducir("Testimonials", "Otros ya han dado el primer paso")}</span>
          </div>
          <Carousel breakPoints={breakPointsTest} className="mb-5 md:p-5">
            <div>
              <div className="mb-5 p-4">
                <div className="text-center">
                  <img
                    src={Model}
                    style={{ width: "100px", borderRadius: "100%", margin: "0 auto -2% auto" }}
                    alt=""
                    className=" borderColorPrimary"
                    width="100"
                  />
                </div>
                <div className="cardSlider md:p-10 m-auto">
                  <div className="md:mb-5">
                    {translate(
                      <p className="text-center colorGray py-5 px-4">
                        “Thanks to TTN, what seemed like an ideal and imaginary scenario has been translated into a system that fixes our
                        problems like no other. We are very grateful and want to continue developing our projects with them”
                      </p>,
                      <p className="text-center colorGray py-5 px-4">
                        “Gracias a TTN, lo que antes parecía un escenario ideal e imaginario a conseguido tomar forma en un sistema que
                        resuelve nuestros problemas como ningún otro. Estamos muy agradecidos y queremos seguir desarrollando nuestros
                        proyectos con ellos“
                      </p>
                    )}
                  </div>
                  <div className="text-center pb-4">
                    <b className="colorPrimary">
                      {traducir("Testimonials", "Luis Villa")}
                      <br />
                      <span className="prussianBlueDark"> {translate("CEO", "CEO de Empresa Importante S.A.")}</span>
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-5 p-4">
                <div className="text-center">
                  <img
                    src={Model}
                    style={{ width: "100px", borderRadius: "100%", margin: "0 auto -2% auto" }}
                    alt=""
                    className=" borderColorPrimary"
                    width="100"
                  />
                </div>
                <div className="cardSlider p-5 m-auto ">
                  {translate(
                    <p className="text-center colorGray p-5">
                      “Thanks to TTN, what seemed like an ideal and imaginary scenario has been translated into a system that fixes our
                      problems like no other. We are very grateful and want to continue developing our projects with them”
                    </p>,
                    <p className="text-center colorGray p-5">
                      “Gracias a TTN, lo que antes parecía un escenario ideal e imaginario a conseguido tomar forma en un sistema que
                      resuelve nuestros problemas como ningún otro. Estamos muy agradecidos y queremos seguir desarrollando nuestros
                      proyectos con ellos“
                    </p>
                  )}

                  <div className="text-center">
                    <b className="colorPrimary">
                      {traducir("Testimonials", "Luis Villa")}
                      <br />
                      <span className="prussianBlueDark"> {translate("CEO", "CEO de Empresa Importante S.A.")}</span>
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-5 p-4">
                <div className="text-center">
                  <img
                    src={Model}
                    style={{ width: "100px", borderRadius: "100%", margin: "0 auto -2% auto" }}
                    alt=""
                    className=" borderColorPrimary"
                    width="100"
                  />
                </div>
                <div className="cardSlider p-5 m-auto ">
                  {translate(
                    <p className="text-center colorGray p-5">
                      “Thanks to TTN, what seemed like an ideal and imaginary scenario has been translated into a system that fixes our
                      problems like no other. We are very grateful and want to continue developing our projects with them”
                    </p>,
                    <p className="text-center colorGray p-5">
                      “Gracias a TTN, lo que antes parecía un escenario ideal e imaginario a conseguido tomar forma en un sistema que
                      resuelve nuestros problemas como ningún otro. Estamos muy agradecidos y queremos seguir desarrollando nuestros
                      proyectos con ellos“
                    </p>
                  )}

                  <div className="text-center">
                    <b className="colorPrimary">
                      {traducir("Testimonials", "Luis Villa")}
                      <br />
                      <span className="prussianBlueDark"> {translate("CEO", "CEO de Empresa Importante S.A.")}</span>
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section> */}
      <div className="mt-15 mb-20">
        <ContactForm />
      </div>
    </>
  );
};
export default Home;
