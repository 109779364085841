// import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaBars } from 'react-icons/fa';
import start from "../assets/startIcon.svg";


export const Nav = styled.nav`
  background: #fff;
  height: 90px;
  display: flex;
  // position: fixed;
  justify-content: space-between;
  // padding: 0.5rem calc((100vw - 1000px) / 2);
  // z-index: 10;
  /* Third Nav */
  /* justify-content: flex-start; */
`;

export const NavLink = styled(Link)`
//   color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    // color: #15cdfc;
    // border: 5px solid #003264
    // border-button-color: #
    // content: "";
    // position: relative;
    // border-bottom: 4px solid #000;
    // left: 20px;
    // bottom: 0;
    // height: 20px;
    // width: 5%;
    // content: "";
    // position: relative;
    // bottom: 0;
    height: 27px;
    // left: -30px;
    // width: 4%;
    border-bottom: 4px solid #003264;
    border-radius: 3px;
  }

  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #003264;
  @media screen and (max-width: 1270px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;


export const NavMenu = styled.div`
  display: flex;
  // align-items: center;
  // margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 1270px) {
    display: none;
  }
`;




export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #256ce1;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;
