import React, { useContext } from "react";
import Logo from "../assets/logoFooter.svg";
import A1 from "../assets/bi_geo-alt.svg";
import A2 from "../assets/Vector.svg";
import A3 from "../assets/Vector-1.svg";
import A4 from "../assets/bi_clock.svg";
import { store } from "./Context/store";
const Footer = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;

  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-content pt-5 pb-5">
            <div className="row ml-4">
              {/* TTN */}
              <div className="col-xl-5 col-lg-5 ttnCompanyDiv  mt-10 md:mt-0">
                <div className="footer-widget md:mx-20">
                  <div className="footer-logo">
                    <a href="/home">
                      <img src={Logo} alt="logo" />
                    </a>
                  </div>
                  <div className="footer-text fs-15">
                    {traslate(
                      <p>
                        We focus on modernizing the technological and <br></br> software processes for clinics and hospitals around the
                        world
                      </p>,
                      <p>
                        Modernizamos los procesos de clínicas y hospitales en todo el mundo a través del desarrollo de software y tecnología
                      </p>
                    )}
                  </div>
                  <div className="footer-text fs-15">
                    {traslate(<p>©TTN Company. All rights reserved.</p>, <p>©TTN Company. Todos los derechos reservados</p>)}
                  </div>
                </div>
              </div>
              {/* CONTACT */}
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30 contactDiv ">
                <div className="footer-widget">
                  <div className="footer-widget-heading ">
                    <h1 className="fw-bold fs-15">{traducir("Contact", "Contacto")}</h1>
                  </div>
                  <div className="row w-75 mb-4">
                    <div className="col-1 px-1">
                      <img src={A1} width="20" alt="" srcset="" />
                    </div>
                    <div className="col">
                      <div>
                        <span className="fw-bold text-white fs-15">{traducir("Address", "Dirección")}</span>
                      </div>
                      <div>
                        <span className="text-white fs-15">Cra 51B #87-50, piso 3 local 337 VIVA Barranquilla</span>
                      </div>
                    </div>
                  </div>
                  <div className="row w-75 mb-5">
                    <div className="col-1 px-1">
                      <img src={A2} width="20" alt="" srcset="" />
                    </div>
                    <div className="col">
                      <div>
                        <span className="fw-bold text-white fs-15">{traducir("Phone", "Teléfono")}</span>
                      </div>
                      <div>
                        <span className="text-white fs-15">+57 304 3783126</span>
                      </div>
                    </div>
                  </div>
                  <div className="row w-75 mb-5">
                    <div className="col-1 px-1">
                      <img src={A3} width="20" alt="" srcset="" />
                    </div>
                    <div className="col-11">
                      <div>
                        <span className="fw-bold text-white fs-15">Email</span>
                      </div>
                      <div>
                        <span className="text-white fs-15">administracion@ttncompany.com</span>
                      </div>
                    </div>
                  </div>
                  <div className="row w-75 mb-5">
                    <div className="col-1 px-1">
                      <img src={A4} width="20" alt="" srcset="" />
                    </div>
                    <div className="col">
                      <div>
                        <span className="fw-bold text-white fs-15">{traducir("Availability ", "Horarios de atención")}</span>
                      </div>
                      <div>
                        <span className="text-white fs-15">{traducir("Monday - Friday", "Lunes - Viernes")} 7:30am - 5:30pm</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ABOUT... */}
              <div className="col-xl-3 col-lg-3 col-md-6 mb-50 aboutDiv">
                <div className="footer-widget">
                  <div className="row">
                    <div className="pb-4">
                      <h1 className="fs-26-20  text-white font-bold">{traducir("About us", "Nosotros")}</h1>
                    </div>
                    <div className="fs-15">
                      {traslate(
                        <span className="text-white fs-15">
                          We are TTN  <br></br>
                          We make your ideas a reality <br></br>
                          Mission, vision and values <br></br>
                          Our team
                        </span>,
                        <span className="text-white fs-15">
                          Somos TTN Company <br></br>
                          Haremos tu idea realidad <br></br>
                          Misión, visión y valores <br></br>
                          Nuestro equipo
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row py-5 ">
                    <div className="pb-4">
                      <h1 className="fs-26-20  text-white font-bold">{traducir("Services", "Servicios")}</h1>
                    </div>
                    <div>
                      {traslate(
                        <span className="text-white fs-15">
                          Software development<br></br>
                          Project Formulation<br></br>
                          Product design
                        </span>,
                        <span className="text-white fs-15">
                          Desarrollo de software<br></br>
                          Formulación de proyectos<br></br>
                          Diseño de productos
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="pb-4">
                      <h1 className="fs-26-20  text-white font-bold">{traducir("Products", "Productos")}</h1>
                    </div>
                    <div>
                      {traslate(
                        <span className="text-white fs-15">
                          Our work<br></br>
                          Project Portfolio
                        </span>,
                        <span className="text-white fs-15">
                          Nuestro trabajo<br></br>
                          Portafolio de proyecto
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
