import React, { useContext } from "react";
// import Select from "react-select";
// import Woman from "../assets/7.png";
// import arrowBlue from "../assets/arrowLeftBlue.svg";
import ContactForm from "./ContactForm";
import { store } from "./Context/store";

const Apply = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;

  // function traducir(ingles, español) {
  //   return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  // }

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }

  const ApplyFormBody = (props) => {
    return (
      <div className="container">
        <div className="row pt-5 ">
          <div className="col-md-6 col-12 md:flex flex-column text-justify md:pt-5 mb-5">
            <div className="col-md-8 flex flex-column">
              <span className="prussianBlue fw-bold fs-40-24">Frontend developer</span>
              <span className="colorGray fw-bold fs-24">Tiempo completo</span>
              <span className="prussianBlueDark fw-bold fs-32 mt-5">¿Qué estamos buscando?</span>
              <span className="colorGray fs-20 mt-1 mb-3">
                Si eres Desarrollador Frontend con +2 años de experiencia en software del sector de la salud esta es la vacante ideal para
                ti.{" "}
              </span>
              <span className="prussianBlueDark fw-bold fs-32 md:mt-5">Habilidades</span>
              <span className="colorGray fs-20 text-justify">
                {/* <ul> */}
                <li>Asegurarse de entregar a tiempo las tareas del sprint </li>
                <li>Trabajar juntos con los demás desarrolladores en la creación de productos</li>

                {/* </ul> */}
              </span>
            </div>
          </div>
          {/* FORM */}
          <div className="col-md-6 col-12 formVacant justify-content-center text-center ">
            <div className="text-center pt-5 d-flex justify-content-center pb-5">
              <span className="prussianBlue fw-bold fs-32 ">Aplica a nuestra vacante</span>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <form className="mx-4 flex flex-column justify-content-center align-items-center">
                <div className="mb-4">
                  <input
                    placeholder="Nombre Completo"
                    type="text"
                    className="form-control md:w-[450px] inputFormContactWork pl-1"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Email"
                    type="email"
                    className="form-control md:w-[450px] inputFormContactWork pl-1"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Celular"
                    type="number"
                    className="form-control md:w-[450px] inputFormContactWork pl-1"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Perfil de LinkedIn"
                    type="url"
                    className="form-control md:w-[450px] inputFormContactWork pl-1"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Expectativa salarial"
                    type="number"
                    className="form-control md:w-[450px] inputFormContactWork pl-1"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Años de experiencia en Frontend"
                    type="number"
                    className="form-control md:w-[450px] inputFormContactWork pl-1"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                {/* <div className="mb-4">
                  <input placeholder="Adjuntar Resume" type="file" className="form-control md:w-[450px] inputFormContactWork pl-1" />
                </div> */}
                <div className=" d-flex justify-content-center pb-4">
                  <button type="submit" className="btnWork">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="">
        <section class="bannerWorkWithUs py-4" id="applyForm">
          <div class="md:mx-5 px-4 pb-5 flex gx-5 h-100 align-items-end justify-content-start">
            {traslate(
              <div class="text-start my-5 md:flex flex-column ">
                <h1 class="display-5 fw-bold colorPrimary mb-2 fs-96 ">CAREERS</h1>
                <span class="display-5 fw-bold colorSecundary mb-2 fs-40">We want you to join our team</span>
              </div>,
              <div class="text-start my-5 md:flex flex-column ">
                <h1 class="display-5 fw-bold colorPrimary mb-2 fs-96 row">Trabaja con nosotros</h1>
                <span class="display-5 fw-bold colorSecundary mb-2 fs-40">Queremos que hagas parte de nuestro equipo</span>
              </div>
            )}
          </div>
        </section>
        <section class="py-5 sectionSecundary bg-white">
          <ApplyFormBody />
        </section>
        <section id="contactForm">
          <ContactForm />
        </section>
      </div>
    </>
  );
};
export default Apply;
