import React, { useState, useContext } from "react";
import Select from "react-select";
import Woman from "../assets/7.png";
import arrowBlue from "../assets/arrowLeftBlue.svg";
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";
import { store } from "./Context/store";

const WorkWithUs = (props) => {
  const [color, setColor] = useState({
    // fontTransitionColor: "textVacancies",
    id: "",
  });
  const globalState = useContext(store);
  const { state } = globalState;

  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function traslate(ingles, español) {
    return <>{state?.english ? <>{ingles}</> : <>{español}</>}</>;
  }
  // const [applyForm, setApplyForm] = useState(false);

  let fullTime = traducir("Full time", "Tiempo completo");
  let apply = traducir("Apply now", "Aplicar ahora");

  const positions = [
    {
      title: "Frontend developer",
      subtitle: fullTime,
      applyNow: apply,
      posAction: "/trabajaConNosotros/Apply",
      id: 1,
    },
    {
      title: "Fullstack developer",
      subtitle: fullTime,
      applyNow: apply,
      posAction: "/trabajaConNosotros/Apply",
      id: 2,
    },
    {
      title: traducir("UI/UX Designer","Diseñador UI/UX"),
      subtitle: fullTime,
      applyNow: apply,
      posAction: "/trabajaConNosotros/Apply",
      id: 3,
    },
    {
      title: "React.js developer",
      subtitle: fullTime,
      applyNow: apply,
      posAction: "/trabajaConNosotros/Apply",
      id: 4,
    },
  ];
  const selectOffersOptions = [
    { value: "Telemed", label: "Frontend developer" },
    { value: "Ordenamientos", label: "Fullstack developer" },
    { value: "vanilla", label: "Diseñador UI/UX" },
    { value: "Vpocket", label: "React.js developer" },
  ];

  return (
    <>
      <div className="">
        <section class="bannerWorkWithUs py-4">
          <div class="md:mx-5 px-4 pb-5 flex gx-5 h-100 align-items-end justify-content-start">
            {traslate(
              <div class="text-start my-5 md:flex flex-column ">
                <h1 class="display-5 fw-bold colorPrimary mb-2 fs-96 ">CAREERS</h1>
                <span class="display-5 fw-bold colorSecundary mb-2 fs-40">We want you to join our team</span>
              </div>,
              <div class="text-start my-5 md:flex flex-column ">
                <h1 class="display-5 fw-bold colorPrimary mb-2 fs-96 row">Trabaja con nosotros</h1>
                <span class="display-5 fw-bold colorSecundary mb-2 fs-40">Queremos que hagas parte de nuestro equipo</span>
              </div>
            )}
          </div>
        </section>
        <section class="py-5 sectionSecundary bg-white">
          <>
            <div class=" w-100 md-w-100  md:flex pt-5 container items-center justify-center responsiveSectTeam teamResponsive">
              
              {/* IMAGE */}
              <div class="md-w-100 teamResponsiveOrder1 p-0 md:flex text-start ttnTeamTextDiv">
                <img className=" imgBRL300Woman bRadius0 width50 m-auto" src={Woman} width="" height="auto" alt="" />
              </div>
              
              {/* TEAM TTN */}
              <div class="md-w-100 teamResponsiveOrder0  px-md-5 md:py-4 md:pt-5 text-justify imgDivWork">
                <div className="col-md-12 text-alignment">
                  <h1 class=" pb-3 prussianBlue fw-bold fs-64 md:text-start justify-center">Team TTN</h1>
                  {traslate(
                    <p class="text-alignment-justify colorGray   fs-26 mb-5">
                      We care about your professional career, and encourage you to achieve your goals. We focus on establishing a working
                      culture where our members feel valued and inspired.
                    </p>,
                    <p class="text-alignment-justify colorGray  fs-26 mb-5">
                      En nuestro equipo nos preocupamos por tu crecimiento profesional y te impulsamos a lograr tus objetivos. Establecemos
                      una cultura donde nuestros integrantes se sientan valorados e inspirados.
                    </p>
                  )}
                  <div className="prussianBlueDark fs-26 fw-bold md_text-center mb-2">
                    {" "}
                    {traducir("We would love to meet you.", "Nos encantaría conocerte, aplica a nuestras vacantes!")}
                  </div>
                </div>
              </div>

            </div>
            {/* //  -----------------VACANCIES--------------------- */}
            <div className="container flex flex-col justify-center md:px-5 ">
              <div className="col-md-6 my-5 text-alignment">
                <span className="fs-54 fw-bold colorSecundary ">{traducir("Jobs", "Vacantes abiertas")}</span>
                <div className="mt-4 md:mr-8 self-center md:self-start">
                  {traslate(<Select
                    options={selectOffersOptions}
                    className=" selectMultiFormContact "
                    placeholder="Job Title"
                    // styles={colourStyles}
                  />,<Select
                    options={selectOffersOptions}
                    className=" selectMultiFormContact "
                    placeholder="Cargo"
                    // styles={colourStyles}
                  />)}
                  
                </div>
              </div>
              {/* CARD CONTAINER */}
              <div className=" md:flex md:flex-wrap justify-center">
                {/* CARD */}
                {positions.length > 0 &&
                  positions.map((item, index) => {
                    let founded = color.id === item.id ? "textVacanciesYellow" : "textVacancies";
                    return (
                      <div key={`container-${index}`} className="col-md-6 justify-start mb-5 md:pr-5">
                        <div
                          key={`cont-${index}`}
                          className="cardWorkComponent  md:mr-3 d-flex flex-column p-5 "
                          onMouseEnter={() => setColor({ ...color, id: item.id })}
                          onMouseLeave={() => setColor({ ...color, id: "" })}
                        >
                          <span key={`span-${index}`} className={`${founded} fs-32 fw-bold`}>
                            {item?.title}
                          </span>
                          <span key={`spa-${index}`} className={`fs-18  colorGray`}>
                            {item?.subtitle}
                          </span>
                          <Link to={`${item?.posAction}#applyForm`}>
                            {/* <Link to={item?.posAction}> */}
                            <div key={`sww-${index}`} className="cursorPointer md:pt-2 ">
                              <span key={`spadwwwn-${index}`} className="fs-18 fw-bold colorSecundary mt-4 d-flex">
                                {item?.applyNow}
                                <img src={arrowBlue} alt="" className="pl-2" />
                              </span>
                            </div>
                            {/* </Link> */}
                          </Link>
                          {/* <li className="nav-item px-4">
                  <a className="nav-link fw-bold colorSecundary fs-18" aria-current="page">
                    Productos
                  </a>
                </li> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
          {/* )} */}
        </section>
        <div className="md:mt-30 mb-20">
          <ContactForm />
        </div>
      </div>
    </>
  );
};
export default WorkWithUs;

// const ApplyFormBody = (props) => {
//   return (
//     <div className="container">
//       <div className="row pt-5 ">
//         <div className="col-6 d-flex flex-column text-justify pt-5">
//             <div className="col-8 d-flex flex-column">
//               <span className="prussianBlue fw-bold fs-40">Frontend developer</span>
//           <span className="colorGray fw-bold fs-24">Tiempo completo</span>
//           <span className="prussianBlueDark fw-bold fs-32 mt-5">¿Que estamos buscando?</span>
//           <span className="colorGray fs-20 mt-1 mb-3">
//             Si eres Desarrollador Frontend con +2 años de experiencia en softwares del sector de la salud este es la vacante ideal para
//             ti. </span>
//           <span className="prussianBlueDark fw-bold fs-32 mt-5">Responsabilidades</span>
//           <span className="colorGray fs-20 text-justify">
//             {/* <ul> */}
//           <li>Asegurarse de entregar a tiempo las tareas del scrum </li>
//           <li>Trabajar juntos con los demas desarrolladores en la creación de productos</li>

//             {/* </ul> */}

//           </span>
//             </div>
//         </div>
//         {/* FORM */}
//         <div className="col-6 formVacant justify-content-center text-center">
//         <div className="text-center pt-5 d-flex justify-content-center pb-5"><span className="prussianBlue fw-bold fs-32 ">Aplica a nuestra vacante</span></div>
//           <div className="d-flex flex-column justify-content-center">
//             <form className="mx-4 d-flex flex-column justify-content-center align-items-center">
//               <div className="mb-4">
//                 <input placeholder="Nombre Completo" type="text" className="form-control w-80 inputFormContactWork pl-1" id="exampleInputEmail1" aria-describedby="emailHelp" />
//               </div>
//               <div className="mb-4">
//                 <input placeholder="Email" type="email" className="form-control w-80 inputFormContactWork pl-1" id="exampleInputEmail1" aria-describedby="emailHelp" />
//               </div>
//               <div className="mb-4">
//                 <input placeholder="Celular" type="number" className="form-control w-80 inputFormContactWork pl-1" id="exampleInputEmail1" aria-describedby="emailHelp" />
//               </div>
//               <div className="mb-4">
//                 <input placeholder="Perfil de Linkedin" type="url" className="form-control w-80 inputFormContactWork pl-1" id="exampleInputEmail1" aria-describedby="emailHelp" />
//               </div>
//               <div className="mb-4">
//                 <input placeholder="Expectativa salarial" type="number" className="form-control w-80 inputFormContactWork pl-1" id="exampleInputEmail1" aria-describedby="emailHelp" />
//               </div>
//               <div className="mb-4">
//                 <input placeholder="Años de experiencia en Frontend" type="number" className="form-control w-80 inputFormContactWork pl-1" id="exampleInputEmail1" aria-describedby="emailHelp" />
//               </div>
//               <div className="mb-4">
//                 <input placeholder="Adjuntar Resume" type="file" className="form-control w-80 inputFormContactWork pl-1"  />
//               </div>
//               <div className=" d-flex justify-content-center">
//                   <button type="submit" className="btnWork">
//                 Enviar
//               </button>
//               </div>
//             </form>
//           </div>
//       </div>
//         </div>
//     </div>
//   );
// };
