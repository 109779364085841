import React, { useContext } from "react";
import S1 from "../assets/Service1.svg";
import S2 from "../assets/Service2.svg";
import S3 from "../assets/Service3.svg";
import P1 from "../assets/TelemedicineProduct.svg";
import P3 from "../assets/biowelAsisPro.png";
import P6 from "../assets/actiFijoPro.png";
import P5 from "../assets/vpocketPro.png";
import P8 from "../assets/sqPro.png";
import phaseProducts from "../assets/phaseProducts.svg";
import soursetProduct from "../assets/soursetProduct.svg";
import vPocketProduct from "../assets/vPocketProduct.svg";
import vacuProduct from "../assets/vacuProduct.svg";
import biowelHealthProduct from "../assets/biowelHealthProduct.svg";
import biowelProduct from "../assets/biowelProduct.svg";
import { store } from "./Context/store";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-elastic-carousel";
import ContactForm from "./ContactForm";

const Services = (props) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1, },
    { width: 550, itemsToShow: 1, itemsToScroll: 1, },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 3 },
    { width: 1450, itemsToShow: 3 },
    { width: 1750, itemsToShow: 3 },
  ];
  const globalState = useContext(store);
  const { state } = globalState;

  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }
  return (
    <>
      <section class="bannerService ">
        <div class="mx-md-5 h-100 px-4 px-md-5">
          <div class="row gx-5 h-100 items-end justify-start py-4">
            <div class="col-md-10 col-12">
              {traslate(
                <div class="text-start my-5 md:pb-28 ">
                  <h1 class="display-5 fw-bold  mb-2  fs-96 text-[#0783FF] ">Make your projects a reality!</h1>
                  <span class="display-5 fw-bold colorSecundary mb-2 fs-40">
                    Learn how to enhance them through our services and solutions.{" "}
                  </span>
                </div>,
                <div class="text-start my-5 md:pb-28">
                  <h1 class="display-5 fw-bold colorPrimary mb-2 fs-96">¡Haz realidad tus proyectos!</h1>
                  <span class="display-5 fw-bold colorSecundary mb-2 fs-40">
                    Conoce como potencializarlos con nuestros servicios y soluciones
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section class="pt-5 sectionSecundary bg-white">
        <div class="row w-100 m-0">
          <div class="col">
            <div class="mb-2 text-center">
              <h1 className="fs-60-24 colorSecundary fw-bold">{traducir("Services and solutions", "Servicios y soluciones")}</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-3">
        <div class=" container flex flex-col items-center justify-center px-md-4 px-md-5">

          <div class="row w-100 m-0 mt-3">

            <div className="col-12 col-lg-6 d-flex">
              <img className="w-85 m-auto" style={{ maxHeight: '300px' }} src={S1} alt="" />
            </div>

            <div className="col-12 col-lg-6 align-self-center mb-5">
              <div className=" pb-4">
                <span className="colorSecundary md:flex md:justify-start 
                fw-bold fs-40-18  ">{traducir("Software development", "Desarrollo de software")}</span>
              </div>
              <div className="">
                {traslate(
                  <p className="colorGray fs-32-16 text-justify">
                    We take development to the next level with fully functional and personalized apps that optimize processes and improve
                    the profitability of your business.
                  </p>,
                  <p className="colorGray fs-32-16 text-justify">
                    Llevamos el desarrollo a otro nivel con aplicaciones web 100% funcionales y personalizadas, para optimizar los procesos
                    y mejorar la rentabilidad de tu empresa.
                  </p>
                )}
              </div>
            </div>

          </div>

          <div class="row w-100 m-0 mb-5 d-flex">

            <div className="col-12 col-lg-6 d-flex orderLg1">
              <img className="w-85 m-auto" style={{ maxHeight: '300px' }} src={S2} alt="" />
            </div>

            <div className="col-12 col-lg-6 align-self-center orderLg0">
              <div className="pb-4">
                <span className="
                colorSecundary fw-bold fs-40-18">{traducir("Implementation Partnerships", "Socios en ejecución")}</span>
              </div>
              <div className="md:text-justify">
                {traslate(
                  <p className="colorGray  fs-32-16">We support you and look for resources to develop your ideas. </p>,
                  <p className="colorGray  fs-32-16">
                    Te apoyamos y buscamos recursos para tus ideas con nuestro equipo de expertos en innovación, desarrollo e investigación.{" "}
                  </p>
                )}
              </div>
            </div>

          </div>

          <div class="row w-100 m-0">

            <div className="col-12 col-lg-6 d-flex">
              <img className="w-85 m-auto" style={{ maxHeight: '300px' }} src={S3} alt="" />
            </div>

            <div className="col-12 col-lg-6  align-self-center ">
              <div className=" pb-4  text-center">
                <span className="colorSecundary fw-bold fs-40-18  
                md:text-right text-center md:flex
                ">{traducir("Product Design", "Diseñamos productos")}</span>
              </div>
              <div className=" text-justify mb-5">
                {traslate(
                  <p className="colorGray  fs-32-16">
                    Our UX/UI specialists will give your projects incredible, functional, and innovative designs, creating friendly
                    interfaces for all users.
                  </p>,
                  <p className="colorGray  fs-32-16">
                    Con nuestro equipo y el empleo de UX y el UI le damos vida a cada uno de tus proyectos con diseños funcionales,
                    efectivos y creamos interfaces amigables con el usuario.{" "}
                  </p>
                )}
              </div>
            </div>

          </div>
        </div>
      </section>
      <section class="md:mt-5  md:py-10 bgServiceProdu flex justify-center items-start">
        <div class="mx-md-5 container px-4 px-md-5 items-center justify-center my-8 md:mt-5">
          <div className="text-center pt-6">
            {traslate(
              <h1 className="colorPrimary fw-bold fs-40">Learn more about our products</h1>,
              <h1 className="colorPrimary fw-bold fs-40">
                Conoce un poco más de
                <br />
                nuestros productos
              </h1>
            )}
          </div>
          <div className="md:py-5 ">
            <Carousel breakPoints={breakPoints} itemsToScroll={2} itemsToShow={2} showArrows={false}
              pagination={true} className="mt-4">
              <div className="col text-center">
                <div>
                  <img className="m-auto" src={P1} alt="" style={{ borderRadius: "15px" }} />
                </div>
                <div className="mt-3">
                  <b className="colorSecundary fs-24">Telemedicina </b>
                </div>
                <div className="">
                  <p className="colorSecundary">
                    {traducir("Telemedicine consultation services", "Atención de consultas por telemedicina")}
                  </p>
                </div>
              </div>
              <div className="col text-center">
                <div>
                  <img className="m-auto" src={phaseProducts} alt="" style={{ borderRadius: "15px" }} />
                </div>
                <div className="mt-3">
                  <b className="colorSecundary fs-24">Phase</b>
                </div>
                <div className="">
                  <p className="colorSecundary">
                    {traducir("Quality management software", "Software de gestión de calidad")}

                  </p>
                </div>
              </div>
              <div className="text-center">
                <div>
                  <img className="m-auto" src={soursetProduct} alt="" style={{ borderRadius: "15px" }} />
                </div>
                <div className="mt-3">
                  <b className="colorSecundary fs-24">Sourset </b>
                </div>
                <div className="">
                  <p className="colorSecundary">
                    {traducir("Fixed asset management", "Administración de activos fijos")}

                  </p>
                </div>
              </div>
              <div className="text-center">
                <div>
                  <img className="m-auto" src={vPocketProduct} alt="" style={{ borderRadius: "15px" }} />
                </div>
                <div className="mt-3">
                  <b className="colorSecundary fs-24">Vpocket </b>
                </div>
                <div className="">
                  <p className="colorSecundary">
                    {traducir("Credit card control", "Control de tarjetas de crédito")}

                  </p>
                </div>
              </div>
              <div className="text-center">
                <div>
                  <img className="m-auto" src={vacuProduct} alt="" style={{ borderRadius: "15px" }} />
                </div>
                <div className="mt-3">
                  <b className="colorSecundary fs-24">Vacu+ </b>
                </div>
                <div className="">
                  <p className="colorSecundary">
                    {traducir("Customer service automation software", "Software de automaticación de atención al cliente")}

                  </p>
                </div>
              </div>
              <div className="text-center">
                <div>
                  <img className="m-auto" src={biowelHealthProduct} alt="" style={{ borderRadius: "15px" }} />
                </div>
                <div className="mt-3">
                  <b className="colorSecundary fs-24">
                    Biowel asistencial</b>
                </div>
                <div className="">
                  <p className="colorSecundary">
                    {traducir("Software for attendance, ordering and more", "Software de asistencia, ordenamientos y más")}

                  </p>
                </div>
              </div>
              <div className="text-center">
                <div>
                  <img className="m-auto" src={biowelProduct} alt="" style={{ borderRadius: "15px" }} />
                </div>
                <div className="mt-3">
                  <b className="colorSecundary fs-24">Biowel </b>
                </div>
                <div className="">
                  <p className="colorSecundary">
                    {traducir("Corporate finance and administration", "Administración y finanzas corporativas")}

                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </section>
      <div className="md:mt-40 mb-20">
        <ContactForm />
      </div>
    </>
  );
};
export default Services;
