import React, { useContext, useState, useRef } from "react";
import Select from "react-select";
import { store } from "./Context/store";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import contact from "../assets/checkContact.svg";

// import chroma from 'chroma-js';

const ContactForm = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;

  const form = useRef();

  const [data, setData] = useState({
    name: "",
    company: "",
    jobTitle: "",
    email: "",
    products: [],
    idea: "",
    phone: "",
    country: "",
  });

  // icon: "success",

  const message = (icon, title, text, confirmButtonText) => {
    // styles
    Swal.fire({
      icon,
      title,
      text,
      confirmButtonText: confirmButtonText || "Aceptar",
      // showCancelButton: true,
      // confirmButtonColor: styles?.confirmButtonColor || "blue",
      // cancelButtonColor: "#d33",
    });
  };
  const isEmptyOrUndefined = (value) => {
    if (value === undefined || value === null || value === "" || value === NaN || value === -1) {
      return true;
    } else {
      return false;
    }
  };

  const validateEmptyString = (arrayString) => {
    /* --------------------------- HOW TO USE EXAMPLE --------------------------- */
    // let validations = validateEmptyString([
    //   { field: data.cum, message: "El campo cum es obligatorio" },
    //   { field: data.cum, message: 'El campo cum es obligatorio', whithOutAlert: true },
    //   { field: selectedActives[0], message: 'Por favor seleccione un artículo' },
    // ]);
    let len = arrayString.length;
    let error = 0;
    let isOk = 0;
    let valid = false;
    arrayString?.length > 0 &&
      arrayString.forEach((e, i) => {
        if (isEmptyOrUndefined(e.field)) {
          error = error + 1;
          if (!e.whithOutAlert) {
            return message(e.icon || "info", e.title || "Campos vacíos", e.message || `Por favor llene todos los campos obligatorios`);
          }
        } else {
          isOk = isOk + 1;
        }
      });

    if (isOk === len) {
      valid = true;
    } else {
      valid = false;
    }

    return { valid };
  };

  let validations = validateEmptyString([
    { field: data.name, whithOutAlert: true },
    { field: data?.email, whithOutAlert: true },
    { field: data?.idea, whithOutAlert: true },
  ]);

  const sendEmail = (e) => {
    if (validations.valid === true) {
      e.preventDefault();
      emailjs.sendForm("service_sk1k6yq", "template_tawh9fs", form.current, "e8swMC2Z8zt6A_F8O").then(
        (result) => {
          Swal.fire({
            timer: 3000,
            imageUrl: `${contact}`,
            imageHeight: 120,
            imageWidth: 120,
            showConfirmButton: false,
            text: `Gracias por contáctarnos, nos estaremos comunicando contigo lo antes posible!`,
            title: ` <span className="fs-16"> Información enviada </span>`,
            customClass: {
              icon: "no-border",
            },
          });
          setData({ ...data, name: "", email: "", idea: "" });
        },
        (error) => {
          // console.log(error.text);
          Swal.fire({
            icon: "error",
            timer: 1500,
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
          });
        }
      );
      e.target.reset();
    } else {
      Swal.fire({
        icon: "info",
        timer: 3500,
        title: "Llenar campos",
        text: "Debe envíar como mínimo los siguientes campos: Nombre, correo e idea. ",
        showConfirmButton: false,
      });
    }
  };

  const selectProductsoptions = [
    { value: "Telemed (Consulta virtual", label: "Telemed (Consulta virtual)" },
    { value: "Ordenamientos (Consultas y medicamentos)", label: "Ordenamientos (Consultas y medicamentos)" },
    { value: "Sourset (Activos fijos)", label: "Sourset (Activos fijos)" },
    { value: "Vpocket (Administración tarjetas)", label: "Vpocket (Administración tarjetas)" },
    { value: "Smart Queue Omnicanal (Gestión call center)", label: "Smart Queue Omnicanal (Gestión call center)" },
    { value: "Smart Queue (Turnos presenciales)", label: "Smart Queue (Turnos presenciales)" },
  ];

  const [currentAlternatives, setCurrentAlternatives] = useState(null);
  const handleAlternatives = (e) => {
    setCurrentAlternatives(e);
    let arrIdEnd = [];
    if (e.length > 0) {
      e.forEach((elem) => {
        arrIdEnd.push(elem.value);
      });
    }
    setData({ ...data, products: arrIdEnd });
  };

  function traducir(ingles, español) {
    return <span>{state?.english ? `${ingles}` : `${español}`}</span>;
  }

  function traslate(ingles, español) {
    return <span>{state?.english ? <>{ingles}</> : <>{español}</>}</span>;
  }
  return (
    <>
      {/* <Modal/> */}
      <section className="bgFrom" id="contact">
        <div className="h-100 px-4 px-md-2 px-lg-4 container  items-center justify-center ">
          <form ref={form} onSubmit={sendEmail}>
            <div className="lg:flex items-center justify-center">
              <div className="col-12 col-lg-4  md:my-5  pt-5 p-4 md-w-100">
                <div className="mt-5">
                  {traslate(
                    <h1 className="fs-32 prussianBlueDark fw-bold">
                      Want to know more about<span className="colorYellow"> our products?</span>
                    </h1>,

                    <h1 className="fs-32 prussianBlueDark fw-bold">
                      Estás interesado en uno de nuestros<span className="colorYellow"> productos?</span>
                    </h1>
                  )}
                </div>
                <div className="mt-5">
                  {traslate(
                    <span className="fs-18 fw-bold Onyx">Choose product...</span>,
                    <span className="fs-18 fw-bold Onyx">Elige el producto...</span>
                  )}
                </div>
                {/* select */}
                <div className="mt-4">
                  <Select
                    options={selectProductsoptions}
                    isClearable
                    className="w-[95%] font-semibold selectMultiFormContact basic-multi-select"
                    isMulti
                    // classNamePrefix="select"
                    name="products"
                    // value={data.products}
                    placeholder={traslate("Seleccione", "Seleccionar... ")}
                    // onChange={(e)=>{setData({...data, product: e.value} || [])}}
                    onChange={(e) => handleAlternatives(e)}

                    // styles={colourStyles}
                  />
                </div>
                <div className="mt-5">
                  {traslate(
                    <span className="fs-18 fw-bold Onyx">Want help landing an idea? </span>,
                    <span className="fs-18 fw-bold Onyx">O te ayudaremos a materializar tus ideas!</span>
                  )}
                </div>
                {/* textarea */}
                <div className="mt-3">
                  {traslate(
                    <textarea
                      className="textAreaForm p-3 w-[95%]"
                      placeholder="Describe your idea..."
                      name="idea"
                      id=""
                      cols="33"
                      rows="7"
                      onChange={(e) => {
                        setData({ ...data, idea: e.target.value });
                      }}
                    ></textarea>,
                    <textarea
                      className="textAreaForm p-3 w-[95%]"
                      placeholder="Cuéntanos tu idea..."
                      name="idea"
                      id=""
                      cols="33"
                      rows="7"
                      onChange={(e) => {
                        setData({ ...data, idea: e.target.value });
                      }}
                    ></textarea>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-7 col-12 md:mt-5 pt-5 p-4 relative flex justify-center mb-[700px] md-w-100 contactResponsive">
                <div className="bg-white pt-5 cardContact absolute -bottom-90 w-full drop-shadow-xl">
                  <div className="text-center pt-3 mb-4">
                    <h1 className="fs-32 md fw-bold prussianBlueDark mb-4"> {traducir("Contact us!", "Datos personales")}</h1>
                    {traslate(
                      <span className="fs-22 fw-bold Onyx">We want to hear more about you</span>,
                      <span className="fs-22 fw-bold Onyx">
                        Para nosotros es importante <br />
                        conocerte
                      </span>
                    )}
                  </div>
                  <div className="md:mb-5">
                    <div className="text-center py-2">
                      {traslate(
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Name"
                          onChange={(e) => {
                            setData({ ...data, name: e.target.value });
                          }}
                          // value={data.name}
                          name="name"
                        />,
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Nombre"
                          onChange={(e) => {
                            setData({ ...data, name: e.target.value });
                          }}
                          // value={data.name}
                          name="name"
                        />
                      )}
                    </div>
                    <div className="text-center py-2">
                      {traslate(
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Company"
                          // onChange={(e) => {
                          //   setData({ ...data, company: e.target.value });
                          // }}
                          // value={data.company}
                          name="company"
                        />,
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Empresa"
                          // onChange={(e) => {
                          //   setData({ ...data, company: e.target.value });
                          // }}
                          // value={data.company}
                          name="company"
                        />
                      )}
                    </div>
                    <div className="text-center py-2">
                      {traslate(
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Job Title"
                          // onChange={(e) => {
                          //   setData({ ...data, jobTitle: e.target.value });
                          // }}
                          // value={data.jobTitle}
                          name="jobTitle"
                        />,
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Cargo"
                          // onChange={(e) => {
                          //   setData({ ...data, jobTitle: e.target.value });
                          // }}
                          // value={data.jobTitle}
                          name="jobTitle"
                        />
                      )}
                    </div>
                    <div className="text-center py-2">
                      {traslate(
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Phone Number"
                          // onChange={(e) => {
                          //   setData({ ...data, jobTitle: e.target.value });
                          // }}
                          // value={data.jobTitle}
                          name="phone"
                        />,
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Número de teléfono"
                          // onChange={(e) => {
                          //   setData({ ...data, jobTitle: e.target.value });
                          // }}
                          // value={data.jobTitle}
                          name="phone"
                        />
                      )}
                    </div>
                    <div className="text-center py-2">
                      {traslate(
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="Country"
                          // onChange={(e) => {
                          //   setData({ ...data, jobTitle: e.target.value });
                          // }}
                          // value={data.jobTitle}
                          name="country"
                        />,
                        <input
                          type="text"
                          className="w-70 inputFormContact p-1"
                          placeholder="País"
                          // onChange={(e) => {
                          //   setData({ ...data, jobTitle: e.target.value });
                          // }}
                          // value={data.jobTitle}
                          name="country"
                        />
                      )}
                    </div>

                    <div className="text-center py-2">
                      <input
                        type="text"
                        className="w-70 inputFormContact p-1"
                        placeholder="Email"
                        name="email"
                        onChange={(e) => {
                          setData({ ...data, email: e.target.value });
                        }}
                        // value={data.email}
                      />
                    </div>
                    <div className="text-center pt-3 pb-5">
                      <button className="btnSecundary bg-white w-30 PrussianBlue120 px-5 py-3" type="submit">
                        <b>{traslate("Send", "Enviar")}</b>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
