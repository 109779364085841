import { Route, Routes, useLocation, BrowserRouter } from "react-router-dom";
import "./App.css";
import AboutUs from "./components/AboutUs";
import { StateProvider } from "./components/Context/store";
import FullLayout from "./components/FullLayout";
import Home from "./components/Home";
import Products from "./components/Product";
import Services from "./components/Services";
import WorkWithUs from "./components/WorkWithUs";
import Apply from "./components/Apply";
import Biowel from "./components/Biowel";
import ContactUs from "./components/contact";
import { useEffect } from "react";
// const languages = {
//   english: true,
// };
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/trabajaConNosotros/Apply") {
      console.log("Application");
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    //STATE PROVIDER IS OUR "REDUX STORE"
    <StateProvider>
      <FullLayout>
        <Routes>
          {/* <Route exact path="/home" element={<Home />} /> */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/nosotros" element={<AboutUs />} />
          <Route exact path="/servicios" element={<Services />} />
          <Route exact path="/productos" element={<Products />} />
          <Route exact path="/productos/biowel" element={<Biowel />} />
          <Route exact path="/trabajaConNosotros" element={<WorkWithUs />} />
          <Route exact path="/trabajaConNosotros/Apply" element={<Apply />} />
          <Route exact path="/contactanos" element={<ContactUs />} />
        </Routes>
      </FullLayout>
    </StateProvider>

    // <StateProvider>
    // {/* <BrowserRouter> */}
    //           <Routes>
    //             <Route path="/" element={<Home />}>
    //               {/* <Route path="inicio" element={<Index />} /> */}
    //               <Route path="nosotros" element={<AboutUs />} />
    //               <Route path="servicios" element={<Services />} />
    //               {/* <Route
    //                 path="usuarios/editar/:_id"
    //                 element={<EditarUsuario />}/> */}
    //               <Route path="productos" element={<Products />} />
    //               <Route path="productos/biowel" element={<Biowel />} />
    //               {/* <Route
    //                 path="proyectos/listarAvances/:_id"
    //                 element={<ListarAvance />}/> */}
    //               {/* <Route
    //                 path="proyectos/registrarAvances/:_id"
    //                 element={<RegistrarAvance />}/> */}
    //               <Route path="trabajaConNosotros" element={<WorkWithUs />} />
    //               <Route path="trabajaConNosotros/apply" element={<Apply />} />
    //               <Route path="trabajaConNosotros" element={<WorkWithUs />} />

    //             </Route>
    //             {/* <Route path="/auth" element={<AuthLayout />}>
    //               <Route path="register" element={<Register />} />
    //               <Route path="login" element={<Login />} />
    //             </Route> */}
    //           </Routes>
    //         {/* </BrowserRouter> */}
    //   </StateProvider>
  );
}

export default App;
