import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logoTtn.svg";
import world from "../assets/world.svg";
import spanish from "../assets/spanishIcon.svg";
import arrow from "../assets/Arrow.svg";
import { store } from "./Context/store";
import Drawer from "./Drawer";
import { Bars, Nav, NavLink, NavMenu } from "./NavbarElements";

const Header = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  // console.log("state: ", state);
  const handlChangeLang = () => {
    dispatch({
      type: "CHANGE_LANGUAGE",
      payload: {
        english: !state.english,
      },
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Nav className="bg-white container flex items-center justify-center rounded-br-[14px] rounded-bl-[14px] fixed w-full max-w-[initial] z-[999] ">
        <Drawer isOpen={isOpen} setIsOpen={setIsOpen} className="hiddenDesktop"/>
        <div className="container m-auto flex justify-between">
          <a className="navbar-brand mt-2 mt-lg-0 " href="/">
            <img src={Logo} alt="MDB Logo" loading="lazy" className="md:h-[70px] h-[50px]" />
          </a>
          {/* <NavLink to="/">
          <img src={Logo} alt="logo" className="h-[70px] " />
        </NavLink> */}
          <Bars onClick={() => setIsOpen(true)} > <img src={spanish} alt="icon" /></Bars>
          <NavMenu className=" ">
            <div className="flex items-center justify-center">
              <NavLink to="/" className=" fw-bold  fs-18">
                <span className="titleNav " activeStyle>
                  Home
                </span>
              </NavLink>
              <NavLink to="/nosotros" activeStyle className="nav-link fw-bold colorSecundary fs-18">
                <span className="titleNav" activeStyle>
                  {" "}
                  {state?.english ? "About us" : "Nosotros"}
                </span>
              </NavLink>
              <NavLink to="/servicios" activeStyle className="nav-link fw-bold colorSecundary fs-18">
                <span className="titleNav" activeStyle>
                  {" "}
                  {state?.english ? "Services" : "Servicios"}{" "}
                </span>
              </NavLink>
              <NavLink to="/productos" activeStyle className="nav-link fw-bold colorSecundary fs-18">
                <span className="titleNav" activeStyle>
                  {" "}
                  {state?.english ? "Products" : "Productos"}{" "}
                </span>
              </NavLink>
              <NavLink to="/trabajaConNosotros" activeStyle className="nav-link fw-bold colorSecundary fs-18">
                <span className="titleNav" activeStyle>
                  {" "}
                  {state?.english ? "Careers" : "Trabaja con nosotros"}
                </span>
              </NavLink>
              <div onClick={() => handlChangeLang()} className=" text-center ml-3 flex items-center justify-center ">
                <span className=" py-2 px-2 me-sm-3 colorSecundary text-decoration-none flex space-x-2 cursorPointer ">
                  <img src={world} alt="" />
                  <b>{state?.english ? "ES" : "EN"}</b>
                  <img src={arrow} alt="" />
                </span>
              </div>
              {/* <Link to={() =>"#contact"} className="flex items-center justify-center "> */}
              <div className="">
                <a href="#contact" className=" btnContact py-2 colorSecundary text-decoration-none">
                  <span className="pl-3 btnImgCall ">ㅤㅤ</span>
                  <b className="px-3 vertical-align-middle fs-18">{state?.english ? "Contact us" : "Contáctanos"}</b>
                </a>
              </div>
              {/* </Link> */}
            </div>
          </NavMenu>
        </div>
      </Nav>
    </>
  );
};
export default Header;

// --------------------------EJEMPLO VIEJO-------------------------------
// const Header = (props) => {

//     const globalState = useContext(store);
//     const { dispatch, state } = globalState;
//     // console.log("state: ", state);
//     const handlChangeLang = () => {
//       dispatch({
//         type: "CHANGE_LANGUAGE",
//         payload: {
//           english: !state.english,
//         },
//       });
//     //   dispatch({
//     //     type: "CHANGE_THEME",
//     //     payload: {
//     //       darkMode: !state.darkMode,
//     //     },
//     //   });
//     };

//   return (
//     <>
//       {/* <nav className="navTtn navbar navbar-expand-lg navbar nav nav-pills nav-justified  bg-white" id="pills-tab" role="tablist">
//                 <div className="container pt-3">
//                     <a className="navbar-brand mt-2 mt-lg-0" href="/home">
//                         <img src={Logo} height="50" alt="MDB Logo" loading="lazy" />
//                     </a> <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
//                         data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
//                         aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
//                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                         <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
//                             <li className="nav-item  px-4"><a
//                             role="tab"
//                             data-toggle="pill"
//                             className="nav-item nav-link active fw-bold colorSecundary fs-18"
//                             aria-current="page"
//                             href="/">Home</a>
//                             </li>
//                             <li className="nav-item px-4"><a
//                             role="tab"
//                             data-toggle="pill"
//                             className="nav-item nav-link  fw-bold colorSecundary fs-18"
//                             aria-current="page"
//                                 href="/nosotros">Nosotros</a>
//                             </li>
//                             <li className="nav-item px-4"><a
//                             role="tab"
//                             data-toggle="pill"
//                             className="nav-item nav-link  fw-bold colorSecundary fs-18"
//                             aria-current="page"
//                             href="/servicios">Servicios</a>
//                             </li>
//                             <li className="nav-item px-4"><a
//                             role="tab"
//                             data-toggle="pill"
//                             className="nav-item nav-link  fw-bold colorSecundary fs-18"
//                             aria-current="page"
//                                 href="/productos">Productos</a>
//                             </li>
//                             <li className="nav-item px-4 align-self-center">
//                                 <a className="btnContact py-2 colorSecundary text-decoration-none" href="#contact"><span
//                                     className="pl-3 btnImgCall">ㅤㅤ</span><b
//                                         className="px-2 vertical-align-middle fs-18">Contáctanos</b></a>
//                             </li>

//                         </ul>
//                     </div>
//                 </div>
//             </nav> */}
//       {/* <nav class="nav nav-pills nav-justified">
//         <a class="nav-link active" href="/">Active</a>
//         <a class="nav-link" href="/">Longer nav link</a>
//         <a class="nav-link" href="/">Link</a>
//         <a class="nav-link disabled" href="/">Disabled</a>
//         </nav> */}
//       <nav className="navTtn navbar navbar-expand-lg navbar bg-white">
//         <div className="container pt-3">
//           <a className="navbar-brand mt-2 mt-lg-0 " href="/home">
//             <img src={Logo} alt="MDB Logo" loading="lazy"  height="50"/>
//           </a>
//           <button
//             className="navbar-toggler"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarSupportedContent"
//             aria-controls="navbarSupportedContent"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <div className="collapse navbar-collapse" id="navbarSupportedContent">
//             <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
//               <Link to="/">
//                 <li className="nav-item  px-4">
//                   <span className=" nav-item active nav-link active fw-bold colorSecundary fs-18" aria-current="page">
//                   {/* {state?.english ? "We develop software for you madafaka" : "Desarrollamos software"} */}
//                     Home
//                   </span>
//                 </li>
//               </Link>
//               <Link to="/nosotros">
//                 <li className="nav-item px-4">
//                   <span className="nav-link fw-bold colorSecundary fs-18" aria-current="page">
//                   {state?.english ? "About us" : "Nosotros"}
//                   </span>
//                 </li>
//               </Link>
//               <Link to="/servicios">
//                 <li className="nav-item px-4">
//                   <span className="nav-link fw-bold colorSecundary fs-18" aria-current="page">
//                   {state?.english ? "Services" : "Servicios"}
//                   </span>
//                 </li>
//               </Link>
//               <Link to="/productos">
//                 <li className="nav-item px-4">
//                   <span className="nav-link fw-bold colorSecundary fs-18" aria-current="page">
//                   {state?.english ? "Services" : "Productos"}
//                   </span>
//                 </li>
//               </Link>
//               <Link to="/trabajaConNosotros">
//                 <li className="nav-item px-4">
//                   <span className="nav-link fw-bold colorSecundary fs-18" aria-current="page">
//                   {state?.english ? "Careers" : "Trabaja con nosotros"}
//                   </span>
//                 </li>
//               </Link>
//               <li className="nav-item px-4 align-self-center">
//                 {/* <a className="btnContact py-2 colorSecundary text-decoration-none " href="#contact"> */}
//                 {/* <a className="btnContact py-2 colorSecundary text-decoration-none " href=""> */}
//                   <Link to={()=> "#contact"}>
//                   <span className="pl-3 btnImgCall">ㅤㅤ</span>
//                   <b className="px-3 vertical-align-middle fs-18">
//                   {state?.english ? "Contact us" : "Contáctanos"}
//                       </b>
//                   </Link>

//                 {/* </a> */}
//                 <div onClick={() => handlChangeLang()} className="col-12 mt-4 text-center">
//                 <span className="btnSecundary py-2 px-2 me-sm-3 colorSecundary text-decoration-none">
//                 <b>{state?.english ? "Español" : "Ingles"}</b>
//               </span>
//             </div>
//               </li>
//               <li>

//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </>
//   );
// };
// export default Header;
